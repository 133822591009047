import React, {Fragment, useCallback, useEffect} from 'react';
import Checkbox from '../../Forms/Checkbox';
import DropDownInput from '../../Forms/Dropdown';
import Input from '../../Forms/Input';
import InputDatePicker from '../../Forms/InputDatePicker';
import $ from 'jquery';
import { ValidateData } from '../../../inc/Validation';
import NewInputDatePicker from '../../Forms/NewInputDatePicker';

    //Component For Mapping Field Input Boxes.

    // ** Props 
        {/* 
            mainClassName,
            className, 
            mainTitle,
            required,
            inputClassName,
            disable,
            type,
            inputType,
            label,
            isChecked,
            value,
            gridObjectFunction,
            id,
            forRendererFields -> function for grid
        */}
    // ----

    // let textBoxInputSettings = [ 
    //     {label:Helper.getLabel(props.language,'',""), className: '', inputType: "text/number/checkbox/datepicker/dropdown", name:'', id:'', }
    // ]

const FieldsRenderer = ({ fieldsArray, mapOtherColumnItems, updateState, currentState, forRendererFields}) => {
    const onInputCheckboxChangeHandler = (name, value) => {  
        updateState((prevState) => {
            return {
                ...prevState,
                [name]:value
            }
        })
    }

    const onInputDatePickerChangeHandler = (name, input) => {
        updateState((prevState) => {
            return {
                ...prevState,
                [name]:input
            }
        })
    } 
    const onInputDatePickerChangeHandlerNew = ( value,name) => {
        updateState((prevState) => {
            return {
                ...prevState,
                [name]:value
            }
        })
    } 
     
    const onInputChangeHandler = useCallback((e) => {
        if (e.target.value !== currentState[e.target.name]) {
            updateState((prevState) => {
                return {
                    ...prevState,
                    [e.target.name]: e.target.value
                }
            })
        }
    }, [currentState])

    return (
        <div className='row'>
            {fieldsArray?.map((item,key) => { 
                let paginationProps = item.pagination ? item?.allDataRef && item?.apiUrl && item?.mapFunction && {
                    allDataRef: item?.allDataRef,
                    mapFunction: item?.mapFunction,
                    pagination: true,
                    apiUrl: item?.apiUrl
                } : {};
                return (
                <>
                        <div className={`mt-2 col-12 ${item.mainClassName ? item.mainClassName : 'd-none'}`}>
                            <h3 className='mb-3'>{item.mainTitle ? item.mainTitle : null}</h3>
                        </div>
                        {item.inputBoxData ? item.inputBoxData.map((element, key) => {
                            let paginationProps = element.pagination ? element?.allDataRef && element?.apiUrl && element?.mapFunction && {
                                allDataRef: element?.allDataRef,
                                mapFunction: element?.mapFunction,
                                pagination: true,
                                apiUrl: element?.apiUrl
                            } : {};
                            return (
                                <div className={item.className ? item.className : 'col-lg-6'} key={key}>
                                    {element.inputType == "text" && 
                                        <Input type='text' id={element.id ? element.id : null} required={element.required ? element.required : false} disable={element.disable ? element.disable : false} name={element.name} label={element.label} onChange={onInputChangeHandler} value={currentState[element.name]} maxLength={element?.maxLength} />
                                    }
                                    {element.inputType == "number" && 
                                        <Input maxLength={element?.maxLength} inputType='number' id={element.id ? element.id : null} required={element.required ? element.required : false} disable={element.disable ? element.disable : false} name={element.name} label={element.label} onChange={onInputChangeHandler} value={currentState[element.name]} />
                                    }
                                    {element.inputType == "dropdown" && 
                                        <DropDownInput {...paginationProps} placeHolder={element.placeHolder ? element.placeHolder : 'Please Select'} ajax={element.ajax ? element.ajax : null} id={element.id ? element.id : null} required={element.required ? element.required : false} isDisable={element.disable ? element.disable : false} label={element.label} options={element.options ? element.options : []} value={currentState[element.name]} name={element.name} onChange={onInputChangeHandler} reRenderRequired={element.reRenderRequired} disableClear={element.disableClear ? element.disableClear : false}  />
                                    }
                                    {element.inputType == "checkbox" &&
                                        <Checkbox id={element.id ? element.id : null} type={element.inputType} name={element.name} onChange={onInputCheckboxChangeHandler} disableWithoutIcon={element.disable ? element.disable : false} label={element.label} isChecked={currentState && (currentState[element.name] == 'on' || currentState[element.name])} /> 
                                    }
                                    {element.inputType == "datepicker" &&
                                        <InputDatePicker id={element.id ? element.id : null} disable={element.disable ? element.disable : false} value={currentState[element.name]} label={element.label} name={element.name} inputClassName={element.inputClassName} required={element.required ? element.required : false} onChange={onInputDatePickerChangeHandler}  /> 
                                    }
                                    {element.inputType == "datepicker_new" &&
                                        <NewInputDatePicker empty={element.emptyDate} id={element.id ? element.id : null} disable={element.disable ? element.disable : false} value={currentState[element.name]} label={element.label} name={element.name}  required={element.required ? element.required : false} onChange={onInputDatePickerChangeHandlerNew}  /> 
                                    }
                                    {element.inputType == "textarea" && 
                                        <div className='textarea_default_wrapper'> 
                                            <label className='mb-2'>{element.label ? element.label : null}{element.required?<span style={{color:'red'}}>*</span>:''}</label>
                                            <textarea 
                                                maxLength={element?.maxLength} 
                                                id={element.id ? element.id : null} 
                                                disable={element.disable ? element.disable : false} 
                                                value={currentState[element.name]} 
                                                name={element.name}
                                                required={element.required ? element.required : false} 
                                                onChange={onInputChangeHandler} 
                                            /> 
                                        </div>
                                    }
                                </div>
                            )
                        }): null}
                        {!item.inputBoxData && 
                        <div className={item.className ? item.className : 'col-lg-6'} key={key}>
                            {item.inputType == "text" && 
                                <Input type='text' id={item.id ? item.id : null} required={item.required ? item.required : false} disable={item.disable ? item.disable : false} name={item.name} label={item.label} onChange={onInputChangeHandler} value={currentState && currentState[item.name]} maxLength={item?.maxLength} /> 
                            }
                            {item.inputType == "number" && 
                                <Input maxLength={item?.maxLength} inputType='number' id={item.id ? item.id : null} required={item.required ? item.required : false} disable={item.disable ? item.disable : false} name={item.name} label={item.label} onChange={onInputChangeHandler} value={currentState && currentState[item.name]} /> 
                            }
                            {item.inputType == "dropdown" && 
                                <DropDownInput  {...paginationProps} placeHolder={item.placeHolder ? item.placeHolder : 'Please Select'} ajax={item.ajax ? item.ajax : null} id={item.id ? item.id : null} required={item.required ? item.required : false} isDisable={item.disable ? item.disable : false} label={item.label} options={item.options ? item.options : []} value={currentState && currentState[item.name]} name={item.name} onChange={onInputChangeHandler} reRenderRequired={item.reRenderRequired} disableClear={item.disableClear} /> 
                            }
                            {item.inputType == "checkbox" &&
                                <Checkbox id={item.id ? item.id : null} type={item.inputType} name={item.name} onChange={onInputCheckboxChangeHandler} disableWithoutIcon={item.disable ? item.disable : false} label={item.label} isChecked={currentState && (currentState[item.name] == 'on' || currentState[item.name])} /> 
                            }
                            {item.inputType == "datepicker" &&
                                <InputDatePicker id={item.id ? item.id : null} disable={item.disable ? item.disable : false} value={currentState[item.name]} label={item.label} name={item.name} inputClassName={item.inputClassName} required={item.required ? item.required : false} onChange={onInputDatePickerChangeHandler}  /> 
                            }
                            {item.inputType == "datepicker_new" &&
                                <NewInputDatePicker empty={item.emptyDate} id={item.id ? item.id : null} disable={item.disable ? item.disable : false} value={currentState && currentState[item.name]} label={item.label} name={item.name} inputClassName={item.inputClassName} required={item.required ? item.required : false} onChange={onInputDatePickerChangeHandlerNew}  /> 
                            }
                            {item.inputType == "textarea" && 
                                <div className='textarea_default_wrapper'> 
                                    <label className='mb-2'>{item.label}{item.required?<span style={{color:'red'}}>*</span>:''}</label>
                                    <textarea 
                                        maxLength={item?.maxLength} 
                                        id={item.id ? item.id : null} 
                                        disable={item.disable ? item.disable : false} 
                                        value={currentState[item.name]} 
                                        name={item.name} 
                                        required={item.required ? item.required : false} 
                                        onChange={onInputChangeHandler}   
                                    />
                                </div>
                            }
                        </div>
                        }
                </>
                )
            })}

            { mapOtherColumnItems && typeof mapOtherColumnItems === 'function' ? mapOtherColumnItems() : null }
        </div>
    )
}

export default FieldsRenderer;