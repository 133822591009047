import React, { Component } from 'react';
import BlueCallToAction from '../../Widgets/BlueCallToAction/BlueCallToAction';
import Helper from '../../../inc/Helper';
import Api from '../../../inc/Api';
import Settings from '../../../inc/Settings';

class PoTopBlueShortDetails extends Component {
    constructor(props){
        super(props);
        this.state = {
            po:{},
            vendor:{}
        }
    }
    componentDidMount(){
        this.loadPo()
    }
    loadPo(){
        let id = this.props.poid;
        if(!id){
            return;
        }
        let api = Api;
        api.setUserToken();
        let that = this;
        api.axios().get(this.props?.apiUrl?this?.props?.apiUrl:Settings.apiPurchaseOrderUrl+`/PO_header/${id}`).then(res => {
            that.setState({
                po:res.data.data
            })
            that.loadVendor(res.data.data.vendor_id)
        })
    }
    loadVendor(vendor_id){
        let api = Api;
        api.setUserToken();
        let that = this;
        api.axios().get(Settings.apiVendorUrl+`/vendor/${vendor_id}`).then(res => {
            that.setState({
                vendor:res.data.data
            })
        })
    }
    componentDidUpdate(prevProps){
        if(this.props.poid != prevProps.poid){
            this.loadPo()
        }
    }
    render() {
        let po = this.state.po;
        let vendor = this.state.vendor;
        let language = null;
        let items = [
            {title:Helper.getLabel(language,'document_number',"Document Number"), subtitle: po.document_number ? po.document_number : ''},
            {title:Helper.getLabel(language,'vendor_code',"Vendor Code"), subtitle: vendor.code ?? '' },
            {title:Helper.getLabel(language,'vendorname',"Vendor Name"), subtitle: vendor.name ?? '' },
        ]
        let receivingItems = [
            {title:Helper.getLabel(language,'document_number',"Document Number"), subtitle: this.props.documentNumber ? this.props.documentNumber : ''},
            {title:Helper.getLabel(language,'source_document_number',"Source Document Number"), subtitle: po.document_number ? po.document_number : ''},
            {title:Helper.getLabel(language,'vendor_code',"Vendor Code"), subtitle: vendor.code ?? ''  },
            {title:Helper.getLabel(language,'vendorname',"Vendor Name"), subtitle: vendor.name ?? '' },
        ]
        return (
            <div className='mb-3 PoTopBlueShortDetails rfq-internal-blue'>
                <BlueCallToAction scrollDitection={this.props?.scrollDitection} items={this.props.type==="receiving"?receivingItems:items} tags={this.props.tags || []}/> 
            </div>
        );
    }
}

export default PoTopBlueShortDetails;