import React from 'react';
import Button from '../inc/Button';
import Popup from '../Popup/Popup';
import $ from 'jquery';
import { formatJson } from '../../inc/Validation';

const VendorCommentsPopup = ({stateObj, setStateObj, applyFormating=false}) => {
    const onPopupClose = () => { 
        $("html").removeClass("rs_popup_open");
        setStateObj({ openPopup: false, field_comment: null })
    };

    return (
        <Popup
            autoOpen={true} 
            width={applyFormating ? "900px" : "650px"}
            onClose={onPopupClose}
        >
            <div className='common_field_comments_class'> 
                <p>
                    {applyFormating ? (formatJson(stateObj?.field_comment) ?? '-') : (stateObj?.field_comment ?? '-')}
                </p>  
            </div>

            <div style={{ display: "flex", justifyContent: "flex-end" }}> 
                <Button
                    isActive={false}  
                    className='black-btn-style'
                    title={"Close"}
                    onClick={onPopupClose}
                />
            </div>
        </Popup>
    )
};

export default VendorCommentsPopup;