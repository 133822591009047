import React, { Component, createRef, Fragment } from 'react';
import MasterComponentWraper from '../../Backend/MasterComponentWraper';
import NavigationHeder from '../../Navigations/NavigationHeder';
import Helper from '../../../inc/Helper';
import Button from '../../inc/Button';
import Collapse from '../../inc/Collapse';
import Input from '../../Forms/Input';
import Settings from '../../../inc/Settings';
import LineViewComponent from '../../Purchase Orders Internal/Edit PO/ListView/LineViewComponent';
import Api from '../../../inc/Api';
import SimpleLoading from '../../Loading/SimpleLoading';
import RsWithRouter from '../../inc/RsWithRouter';
import Tabs from '../../Widgets/Tabs/Tabs';
import MasterSidebar from '../../Sidebars/MasterSidebar';
import NoteAttachmentHistory from '../../Widgets/NoteAttachmentHistory';
import FormValidator from '../../../inc/FormValidator';
import PoTopBlueShortDetails from '../../Purchase Orders Internal/Widget/PoTopBlueShortDetails';
import NewInputDatePicker from '../../Forms/NewInputDatePicker';
import DatepickerFunctions from '../../../inc/DatepickerHelper';
import { checkIfArrayIsEmpty, getPopupMessageBasedOnStatus, objectIsEmpty } from '../../../inc/Validation';
import Dropdown from '../../Forms/Dropdown';
import axios from 'axios';
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader';
import MasterComonent from '../../Backend/MasterComonent';
import ButtonNew from '../../inc/ButtonNew';
import WorkFlowCommonHeaderAPICalls from '../../Header/WorkflowCommonHeaderAPICalls';
import { connect } from 'react-redux';
import WorkFlowCommonHeader from '../../Header/WorkFlowCommonHeader';
import WorkFlowOffCanvas from '../../Header/WorkFlowOffCanvas';
import ApplySecurityRoles from '../../SecurityRoles/ApplySecurityRoles';
import Gui_id_list from '../../../inc/Gui_id_list';
import Alert from '../../inc/Alert';
import FixedNavigationHeader from '../../Navigations/FixedNavigationHeder';
import TabContentWraper from '../../Widgets/Tabs/TabContentWraper';
import TotalWidget from '../../Widgets/TotalWidget/TotalWidget';
 
class EditGoodsReceivingNote extends Component {
    constructor(props){
        super(props);
        this.isPOHeaderLoad = false;
        this.currentSearchTerm = '';
        this.cancelTokenSource = axios.CancelToken.source();
        this.allData = createRef();
        this.wrapperDiv = createRef();
        this.workflowApiCalled = false;
        this.grn_document_id = this.props?.rs_router?.params?.id ? parseInt(this.props?.rs_router?.params?.id) : null;
        this.state = {
            isLoading:false,
            height: 0,
            shipmentHeader:{},
            workflow_action_history: [],
            showCanvas: false, 
            workflow_instance_exist: null,
            workflow_eligible: null,
            selectedSupNav: 'Header View',
            workflow_name: null,
            scrollDitection: 'down',
            showCurrentAssignees: false,
            isPosting:false,
            vendor:{},
            vendor_site:{},
            selectedDocument:null,
            saveBtnApi: false,
            documentStatus:''
        }
        this.sectionName = 'goods-receiving-notes-edit-form'
        this.validationConfigure()
        this.validationConfigureEdit()
    }

    resizeWindowHandle = () => {
        Helper.resizeWindowHandle(this.wrapperDiv, (newHeight) => {
            if (this.state.height !== newHeight) {
                this.setState({ height: newHeight });
            }
        }, window);
    };  

    getSearchParam(name) {
        const params = new URLSearchParams(this?.props?.rs_router?.location?.search);
        return params.get(name);
    }
    
    componentDidMount(){
        this.resizeWindowHandle();
        this.loadShipmentHeader(); 
        if(this.props?.user){ 
            this.getWorkFlowCalls(); 
        }
    }     
    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.isLoading !== this.state.isLoading ||
            this.wrapperDiv?.current?.clientHeight !== prevState.wrapperDiv
        ) { 
            this.resizeWindowHandle();
        }
        if (JSON.stringify(prevProps?.user) !== JSON.stringify(this.props?.user)) { 
            this.getWorkFlowCalls();
        }
    }    
    validationConfigure(){
        const language = null;
        let fieldConfig = [
            {name:'receiving_reference_number',displayName:Helper.getLabel(language,'good_receipt_number_required','Goods receipt number'),types:['Required']},
            {name:'accounting_date',displayName:Helper.getLabel(language,'accounting_date_are_required','Accounting date are required'),types:['Required']},
            {name:'document_date',displayName:Helper.getLabel(language,'document_date_are_required','Document date are required'),types:['Required']},
        ]
        this.validator = new FormValidator(fieldConfig,language);
    }
   validationConfigureEdit(){
        const language = null;
        let fieldConfig2 = [
            {name:'document_number',displayName:Helper.getLabel(language,'document_no','Document number'),types:['Required']},
            {name:'accounting_date',displayName:Helper.getLabel(language,'accounting_date_are_required','Accounting date are required'),types:['Required']},
            {name:'document_date',displayName:Helper.getLabel(language,'document_date_are_required','Document date are required'),types:['Required']},
        ]
        this.editValidator = new FormValidator(fieldConfig2,language);
    }
    loadShipmentHeader(shipmentId){
        const {id,type} = this.props?.rs_router?.params;
        if(this.isPOHeaderLoad && type==="edit"){
            return;
        }
        this.isPOHeaderLoad = true;
        if(!id && type==="edit"){
            return;
        }
        let api = Api, that =this;
        api.setUserToken();
        this.setState({
            isLoading:true
        })
        api.axios().get(Settings.apiPurchaseOrderUrl+`/sh_header/${type==="new"?shipmentId:id}`).then(function(res){
            that.setState({
                isLoading:false,
                shipmentHeader:res.data.data
            })
            that.loadVendorById(res?.data?.data?.vendor_id)
            that.loadVendorSite(res?.data?.data?.vendorsite_id)
            that.loadDocumentStatus(res?.data?.data?.document_status)
        }).catch((res) => {
            that.setState({
                isLoading:false,
                shipmentHeader:{}
            })
        })
    }

    async getWorkFlowCalls() {  
        if(!this.grn_document_id) return;

        if(this.workflowApiCalled) return;
        this.workflowApiCalled = true;
        
        const workflowCalls = new WorkFlowCommonHeaderAPICalls();

        const workflowActionHistory = await workflowCalls.getWorkFlowActionHistory(Settings.source_id.goods_receiving, this.grn_document_id);
    
        if(workflowActionHistory) this.setState({ workflow_action_history: workflowActionHistory });
  
        const trigger = await workflowCalls.getWorkFlowTriggers();
        const mode = !checkIfArrayIsEmpty(trigger) && trigger?.find(id => id.name == "Update")?.id

        const workflowExist = await workflowCalls.getWorkFlowInstanceExist(this.props.user.tenant_id, Settings.source_id.goods_receiving, this.grn_document_id);
        const eligibleWorkflow = await workflowCalls.checkWorkFlowForSpecificModule(Settings.source_id.goods_receiving, this.props.user.tenant_id, mode);

        if(workflowExist) {
            this.setState({
                workflow_instance_exist: workflowExist,
                workflow_name: workflowExist?.workflow_name
            });
        }
        if(eligibleWorkflow) this.setState({ workflow_eligible: eligibleWorkflow });
    }
    loadVendorById(vendor_id){
        let api = Api;
        api.setUserToken();
        let that = this;
        api.axios().get(Settings.apiVendorUrl+'/vendor/'+vendor_id).then(res=>{
            that.setState({
                vendor:res.data.data
            })
        })
    }
    loadDocumentStatus(document_status){
        let api = Api;
        api.setUserToken();
        let that = this;
        api.axios().get(Settings.apiUrl+'/enum/SH_DocumentStatus').then(res=>{
            let currentStatus = res?.data?.data?.find((item) => item.id === document_status)
            that.setState({
                documentStatus: currentStatus?.name
            })
        })
    }
    loadVendorSite(vendorsite_id){
        let api = Api;
        api.setUserToken();
        let that = this;
        api.axios().get(Settings.apiVendorUrl+'/vendorsite/'+vendorsite_id).then(res=>{
            that.setState({
                vendor_site:res.data.data
            })
        })
    }
    async executePOSTApiforGRN(api, newPostData, that, afterSaved) {
        try {
            const resSh = await api.axios().post(Settings.apiPurchaseOrderUrl + '/sh_headers/' + Settings.shType.po, newPostData, { cancelToken: this.cancelTokenSource.token });
            
            Helper.alert(resSh.data.message);
            that.props.rs_router.navigate("/goods-receiving/edit/" + resSh.data.shipment_id);
            that.setState({ isLoading: false });
            this.loadShipmentHeader(resSh.data.shipment_id);
            this.grn_document_id=parseInt(resSh?.data?.shipment_id)
            
            if (afterSaved) {
                afterSaved();
            }
        } catch (error) {
            getPopupMessageBasedOnStatus(error);
            that.setState({ isLoading: false });
        }
    }  

    async updateShipmentHeader(shipmentHeader, newData, afterSaved, api, that) {
        try {
            const res = await api.axios().put(`${Settings.apiPurchaseOrderUrl}/sh_header/${shipmentHeader.shipment_id}`, newData);
            Helper.alert(res.data.message);
            that.setState({ isLoading: false });
            
            if (afterSaved) {
                afterSaved();
            }
        } catch (error) {
            getPopupMessageBasedOnStatus(error);
            that.setState({ isLoading: false });
        }
    }
    
    saveHandler(event,afterSaved, workflowInitialization){
        let api = Api,that = this;
        api.setUserToken();
        let shipmentHeader = this.state.shipmentHeader;
        let {type} = this.props.rs_router.params;
        let newPostData = {
            purchase_order_id: this.state?.selectedDocument?.purchase_order_id,
            document_number: this.state?.selectedDocument?.document_number,
            receiving_reference_number:shipmentHeader?.receiving_reference_number,
            description: this.state?.selectedDocument?.description,
            vendor_id: this.state?.selectedDocument?.vendor_id,
            vendorsite_id: this.state?.selectedDocument?.vendorsite_id,
            vendorgroup_id: this.state?.selectedDocument?.vendorgroup_id,
            accounting_date:DatepickerFunctions.convertDateForDataBase(shipmentHeader?.accounting_date) ?? DatepickerFunctions.convertDateForDataBase(new Date()),
            document_date: DatepickerFunctions.convertDateForDataBase(shipmentHeader?.document_date) ?? DatepickerFunctions.convertDateForDataBase(new Date()),
            delivery_date: this.state.selectedDocument?.delivery_date,
            entity_id: this.state.selectedDocument?.entity_id,
            document_status: this.state.selectedDocument?.document_status,
            sh_type: Settings.shType.po,
            type: this.selectedDocument?.type,
        }
        if(type==="new"){
            if(!this.editValidator.isValid(newPostData)){
                this.editValidator.displayMessage(this);
                return;
            }
        }else{
            if(!this.validator.isValid(shipmentHeader)){
                this.validator.displayMessage(this);
                return;
            }
        }
        this.setState({
            isLoading:true
        })
        let newData = workflowInitialization ? { workflow_initiated: true } : {
            receiving_reference_number: shipmentHeader.receiving_reference_number,
            contactsource_id:null,
            attachmentsource_id:null,
            notesource_id:null,
            addresssource_id:null,
            accounting_date:DatepickerFunctions.convertDateForDataBase(shipmentHeader.accounting_date),
            document_date:DatepickerFunctions.convertDateForDataBase(shipmentHeader.document_date) 
        }
        
        if(type==="new"){
            Helper.createDebouncedAPIFunction([
                async () => await this.executePOSTApiforGRN(api, newPostData, that, afterSaved)
            ].map(func => func.bind(this)), that.setState.bind(that), this.cancelTokenSource, true)();                         
        }

        else if(!type || type==="edit"){
            Helper.createDebouncedAPIFunction([
                async () => await this.updateShipmentHeader(shipmentHeader, newData, afterSaved, api, that)
            ].map(func => func.bind(this)), that.setState.bind(that), this.cancelTokenSource, true)();                         
        }
    }
    setStateHandlingCanvas(value){
        this.setState({ showCanvas: value });
      }
    
      setStateHandlingCurrentAssignee(value){
        this.setState({ showCurrentAssignees: value });
      }
    
    onInputChangeHandler(event){
        let oldData = this.state.shipmentHeader;
        this.setState({
            shipmentHeader:{
                ...oldData,
                [event.target.name]:event.target.value
            }
        })
    }
    onDateChangeHanlder(date,name){
        let oldData = this.state.shipmentHeader;
        this.setState({
            shipmentHeader:{
                ...oldData,
                [name]:date
            }
        })
    }
    mapApiItemToOption(item){
        return {
            id: item.document_number || 'null',
            text: item.document_number + ' - ' + item.description,
        };
    };

    addComponentRight(){
        return(
            <div className='parent-sidebar-cn'>
                <MasterSidebar>
                    <NoteAttachmentHistory
                        openCanvas={this.setStateHandlingCanvas.bind(this)}
                        disableAttachments={!(this.props?.security?.canEdit(Gui_id_list.receiving.goods_receiving.goods_receiving_main_edit_screen_button) && this.props?.user?.usertype === 0)}
                        openCurrentAssignees={this.setStateHandlingCurrentAssignee.bind(this)}
                        source_id={Settings.source_id.goods_receiving}
                        hideHistory={!this.state.workflow_action_history?.history?.length}
                        historyArray={this.state.workflow_action_history}
                        notesource_integrator={this.grn_document_id}
                        height={this.state.height}
                        attachmentsource_integrator={this.grn_document_id} 
                        condition={this.state.shipmentHeader?.document_status===2}
                    />
                </MasterSidebar>

                {this.state.showCanvas && 
                    <WorkFlowOffCanvas
                        isOpen={this.state.showCanvas}
                        type={{ label: "Action History", value: 9 }}
                        closeSidebar={this.setStateHandlingCanvas.bind(this)}
                        source_id={Settings.source_id.goods_receiving}
                        source_item_id={this.grn_document_id}
                    />
                }

                {this.state.showCurrentAssignees &&
                    <WorkFlowOffCanvas
                        isOpen={this.state.showCurrentAssignees}
                        type={{ label: "View current assignment", value: 43 }}
                        closeSidebar={this.setStateHandlingCurrentAssignee.bind(this)}
                        source_id={Settings.source_id.goods_receiving}
                        source_item_id={this.grn_document_id}
                    />
                }
            </div>
        )
    }
    onPostingHandler(event){
        let shipmentHeader = this.state.shipmentHeader;
        if(!this.validator.isValid(shipmentHeader)){
            this.validator.displayMessage(this);
            return;
        }
        let  that  = this;
        this.saveHandler(event,()=>{
            let {id} = that.props.rs_router.params;
            if(that.props.rs_router?.location?.pathname===`/purchase-order/good-receiving/edit/${id}`){
                that.props.rs_router.navigate(-1)
            }else{
                that.props.rs_router.navigate(-2)
            }
            that.isPOHeaderLoad = false;
            that.loadShipmentHeader();
        }, true);
    }
    canEditShipment(){
        let shipmentHeader = this.state.shipmentHeader;
        let disableComponentFromSecurity = this.props?.user?.usertype !== 0  ? false  : !(this.props?.security?.canEdit(Gui_id_list.receiving.goods_receiving.goods_receiving_main_edit_screen_button));
        
        if( disableComponentFromSecurity || (shipmentHeader.document_status && shipmentHeader.document_status != '0')){
            return false;
        }
        return true;
    }
    
    onDeleteButtonClick(event){
        Helper.confirmMe(() => {
            let that  = this;
            let { id, type } = that.props.rs_router.params;
            let api = Api
            api.setUserToken()
            api
            .axios()
            .delete(
                Settings.apiPurchaseOrderUrl +
                `/sh_header/${id}`,
            )
            .then(function (res) {
                if (res.data.status === 'success') {
                    Helper.alert(res.data?.message, 'success')
                    const timer = setTimeout(() => {
                        type==="edit"? that.props.rs_router.navigate(-2): that.props.rs_router.navigate(-1)
                    }, 500)
                    return () => clearTimeout(timer)
                }
            })
            .catch((res) => {
                getPopupMessageBasedOnStatus(res) 
            })
        },
        "Goods Receiving Note will be deleted",
            null,
        "Are you sure you want to delete?",
            420
        );
    }

    handleStatusChangeOfDocument(){ 
        let api = Api;
        api.setUserToken();
        let that = this;
        let shipmentHeader = this.state.shipmentHeader;
        this.setState({ isLoading: true });
        this.updateShipmentHeader(shipmentHeader, { status: 0 }, null, api, that);
        this.loadShipmentHeader();
    }

    submitWorkflow(comment){}

    reloadFunctionWorkflow(){ 
        this.workflowApiCalled = false;
        this.loadShipmentHeader(); 
        this.getWorkFlowCalls();
    }

    addComponentTop(){
        let goodsReceiving = this.state.shipmentHeader;
        let {type} = this.props.rs_router.params;
        return(
            <div className='misc-header-blue'>
                <PoTopBlueShortDetails scrollDitection={this.state.scrollDitection} poid={type==="new"?this.state.selectedDocument?.purchase_order_id:goodsReceiving.purchaseorder_id} type="receiving" documentNumber={goodsReceiving?.receiving_reference_number} tags={[{ title: this?.state?.documentStatus?.replace(/_/g, ' ') || "-" }]} />
            </div>
        )
    }
    render() {
        let {id,type} = this.props.rs_router.params;

        let location = this.props.rs_router.location;
        if(!id && !type){
            return;
        }
        let language = null;
        let goodsReceiving = this.state.shipmentHeader;
        let vendor = this.state.vendor;
        let vendor_site = this.state.vendor_site;
        let navs = [
            { title: Helper.getLabel(language, 'general_information', 'General Information'), data_section: "one", active_status: 'active_tab' },
            { title: Helper.getLabel(language, 'lines', 'Lines'), data_section: "two", active_status: '' }
        ]
        let purchaseorder_id = goodsReceiving?.purchaseorder_id;
        
        let security = this.props?.security;
        let isBtnDisableFromSecurity = !security?.canEdit(Gui_id_list.receiving.goods_receiving.goods_receiving_main_edit_screen_button) && this.props?.user?.usertype === 0;

        if(!security?.canView(Gui_id_list.receiving.goods_receiving.goods_receiving_main_edit_screen) && this.props?.user?.usertype === 0){ 
            return (
                <MasterComonent>
                    <Alert message='You do not have the necessary permissions to access this screen. Please contact your administrator for assistance.' type='danger' />
                </MasterComonent>
            )
        }; 
        return (
            <div style={{position:"relative"}} className={
                this.props?.user?.usertype === 1 ?
                    'request_for_quotation_detail_screen external-detail-screens external-theme' 
                        :
                    ''
                }
            >
                <MasterComonent>
                    <div className='goods_receiving_create_note_page edit_gr_page'>
                        <div className='container-fluid scrollBarBlinkControl'>
                            <div ref={this.wrapperDiv}>
                                <NavigationHeder backUrl={this.props.backUrl ? this.props.isDynamicBackUrl ?this.props?.backUrl:this.props.backUrl +'/'+purchaseorder_id : type==="edit"&&location?.state===null?-2:-1 } title={ !this.canEditShipment() || location?.pathname==="/goods-receiving/new" ? Helper.getLabel(language,'goods_receiving_note','Goods Receiving Note') : Helper.getLabel(language,'edit_good_receiving_note','Edit goods receiving note')} hideMoreBtn={true}>
                                    { this?.state?.workflow_instance_exist?.instance_details?.last_instance_status?.[4] === 'Rejected' && this.state.shipmentHeader?.document_status === 3 && (
                                        <Button
                                            onClick={this.handleStatusChangeOfDocument.bind(this)}
                                            className="blue-btn-style"
                                            title={Helper.getLabel(language, 'edit', 'Edit')}
                                            isDisable={isBtnDisableFromSecurity}
                                        />
                                    )}
                                    <Button title={'Invoices'} onClick={()=>{this.props.rs_router.navigate(`/invoice-list?receiving_id=${id}`)}} isDisable={location?.pathname==="/goods-receiving/new"}  className='brown-btn-style'></Button>
                                    <Button title={"Delete"} className="red-btn-style" isDisable={ location?.pathname==="/goods-receiving/new" ||  !this.canEditShipment() || isBtnDisableFromSecurity } onClick={this.onDeleteButtonClick.bind(this)}/>
                                    <TotalWidget source_id={Settings.source_id.goods_receiving} integrator={this.grn_document_id} />
                                    <Button id="btn_master_save" className="blue" isDisable={  !this.canEditShipment() || this.state.saveBtnApi || isBtnDisableFromSecurity }  title={Helper.getLabel(language, "save", "Save")}  onClick={this.saveHandler.bind(this)} />
                                    <Button title={'Post'} isActive={true} isDisable={ this.state.isPosting || isBtnDisableFromSecurity || !this.canEditShipment() || this.state.saveBtnApi || location?.pathname==="/goods-receiving/new" } onClick={ this.onPostingHandler.bind(this)}/>
                                </NavigationHeder>

                                { this?.state?.workflow_instance_exist?.instance_exist ? 
                                    !this?.state?.isLoading &&
                                    <WorkFlowCommonHeader
                                        removeHeaderMargins={true}
                                        submitWorkflowAction = {
                                            !this?.state?.workflow_instance_exist?.instance_exist &&
                                            this?.state?.workflow_eligible && 
                                            this.submitWorkflow.bind(this)
                                        }
                                        workflowInstanceDetails={this?.state?.workflow_instance_exist?.instance_details}
                                        instance_task_id={this.getSearchParam("instance_id")}
                                        module_id={!this.getSearchParam("instance_id") ? Settings.source_id.goods_receiving : null}
                                        source_id={Settings.source_id.goods_receiving}
                                        source_item_id={this.grn_document_id}
                                        SubmitWorkflowObjDetails={this?.state?.workflow_name}
                                        module_item_id={!this.getSearchParam("instance_id") ? this.grn_document_id : null}
                                        workflowInstanceExist={this?.state?.workflow_instance_exist?.instance_exist}
                                        workflowEligible={this?.state?.workflow_eligible}
                                        workflowName={"Goods Receiving Workflow"}
                                        reloadFunction={this.reloadFunctionWorkflow.bind(this)}
                                    /> : ''
                                }

                                <FixedNavigationHeader
                                    addComponentTop={this.addComponentTop.bind(this)}
                                    sectionName={this.sectionName}
                                    navs={navs}
                                    selectedSupNav={this.state.selectedSupNav}
                                    scrollDitection={this.state.scrollDitection}
                                />
                            </div>

                            <TabContentWraper
                                height={this.state.height} 
                                setScrollDitection={(newComponent) => this.setState({ scrollDitection: newComponent })}
                                leftSideContent={
                                    <>
                                        <div className="lists_contant one mb-4" id={`${this.sectionName}General Information`} style={{ position: "relative" }}>
                                            <Collapse open={true} title={Helper.getLabel(language,'general_information','General Information')}>
                                                <div className='row'>
                                                    <div className='col-xs-12 col-sm-4'>
                                                        {(location.pathname?.includes("/purchase-order/") || (type==="edit")) && <Input disable={true}  name='document_number' label={Helper.getLabel(language,'document_no','Document number')} value={goodsReceiving.document_number} onChange={this.onInputChangeHandler.bind(this)}/>}
                                                        {
                                                        (type==="new") && <Dropdown id="goods-receiving-document-parent" label={Helper.getLabel(language,'document_no','Document number')}   pagination={true}
                                                        reRenderRequired={true}
                                                        apiUrl={Settings.apiPurchaseOrderUrl + '/PO_header?type=0'}
                                                        currentSearchTerm={this.currentSearchTerm}
                                                        mapFunction={this.mapApiItemToOption}
                                                        allDataRef={this.allData} 
                                                        additionalParams={{document_status:1}} 
                                                        required={true}
                                                        onChange={(e) => {
                                                            if (e.target.value.includes('null')) {
                                                            Helper.alert(
                                                                'Document number cannot be null. Please select other option.',
                                                                'error',
                                                            )
                                                            } else {
                                                            const selected = this.allData?.current?.find(
                                                                (doc) => doc.document_number === e.target.value,
                                                            )
                                                            this.setState({
                                                                selectedDocument : selected || {},
                                                            })
                                                            }
                                                        }}
                                                        /> 
                                                        }
                                                    </div>
                                                </div>
                                                <h3 className='inline_title_1'>{Helper.getLabel(language,'vendor','Vendor')}</h3>
                                                <div className='row'>
                                                    <div className='col-xs-12 col-sm-4 col-md-4'>
                                                        <Input disable={true} name="vendor_code" id="vendor_code" value={type==="new"?this.state.selectedDocument?.vendor_code:vendor.code} label={Helper.getLabel(language,'vendor_code','Vendor code')} onChange={this.onInputChangeHandler.bind(this)}/>
                                                    </div>
                                                    <div className='col-xs-12 col-sm-4 col-md-4'>
                                                        <Input disable={true}  name="vendor_name" id="vendor_name" value={type==="new"?this.state.selectedDocument?.vendor_name:vendor.name} label={Helper.getLabel(language,'vendor_name','Vendor name')} onChange={this.onInputChangeHandler.bind(this)}/>
                                                    </div>
                                                    <div className='col-xs-12 col-sm-4 col-md-4'>
                                                        <Input disable={true}  name="vendor_site" id="vendor_site" value={type==="new"?this.state.selectedDocument?.vendorsite_name:vendor_site.name}  label={Helper.getLabel(language,'vendor_site','Vendor site')} onChange={this.onInputChangeHandler.bind(this)}/>
                                                    </div>
                                                </div>
                                                <h3 className='inline_title_1'>{Helper.getLabel(language,'goods_receiving_details','Goods Receiving Details')}</h3>
                                                <div className='row'>      
                                                <div className='col-xs-12 col-sm-4 col-md-4'>
                                                        <Input name='receiving_reference_number' hasError={this.validator.hasError('receiving_reference_number')}  disable={ !this.canEditShipment() } required={type=="new"?false:true} label={Helper.getLabel(language,'goods_receipt_number','Goods receipt number')} value={goodsReceiving.receiving_reference_number} onChange={this.onInputChangeHandler.bind(this)} maxLength={20}/>
                                                    </div>
                                                    <div className='col-xs-12 col-sm-4 col-md-8'>
                                                        <Input disable={true}  name='description' inputType="textarea" label={Helper.getLabel(language,'description','Description')} value={type==="new"?this.state.selectedDocument?.description:goodsReceiving.description} onChange={this.onInputChangeHandler.bind(this)}/>
                                                    </div>
                                                </div>
                                                <h3 className='inline_title_1'>{Helper.getLabel(language,'dates','Dates')}</h3>
                                                <div className='row'>
                                                    <div className='col-xs-12 col-sm-4 col-md-4'>
                                                        <NewInputDatePicker label ={Helper.getLabel(language,'accounting_date','Accounting date')} name={"accounting_date"} id = {"accounting_date"} value = {goodsReceiving.accounting_date ? goodsReceiving.accounting_date : null } disable = {true} onChange ={this.onDateChangeHanlder.bind(this)}/>
                                                    </div>
                                                    <div className='col-xs-12 col-sm-4 col-md-4'>
                                                        <NewInputDatePicker label ={Helper.getLabel(language,'document_date','Document date')} name={"document_date"} id = {"document_date"} value = {goodsReceiving.document_date ? goodsReceiving.document_date : null } disable = {!this.canEditShipment()} onChange ={this.onDateChangeHanlder.bind(this)}/>
                                                    </div>
                                                </div>
                                            </Collapse>
                                        </div>
                                        <div className="lists_contant two" id={`${this.sectionName}Lines`} style={{ position: "relative" }}>
                                            {
                                                !this.state.isLoading || (!type && goodsReceiving.shipment_id) ? 
                                                <div className='rfq-line-view'>
                                                <LineViewComponent 
                                                    defaultValues ={{
                                                        /*
                                                        requester_id:editPO.requester_id,
                                                        warehouse_id:editPO.warehouse_id,
                                                        delivery_date:editPO.delivery_date,
                                                        taxgroupitem_id:editPO.taxgroupitem_id,
                                                        taxgroup_id:editPO.taxgroup_id,
                                                        entity_id:editPO.entity_id,
                                                        site_id:editPO.site_id,
                                                        orderer_id:editPO.orderer_id
                                                        */
                                                    }}
                                                    lineGridId = 'good_receiving_line_grid'
                                                    userType={this?.props?.user?.usertype}
                                                    viewType = "shipment"
                                                    canShipmentEdit = {this.canEditShipment()}
                                                    shipmentType = 'good_receiving'
                                                    source_id={Settings.source_id.goods_receiving} 
                                                    line_source={Settings.source_id.goods_receiving} 
                                                    isEditable={false} 
                                                    lineGridTitle = {Helper.getLabel(null,'lines',"Lines")}
                                                    header_id={goodsReceiving.shipment_id}
                                                    purchase_status={0}
                                                    document_status={0}
                                                    delivery_date={null}
                                                    master_save_button_id = "btn_master_save"
                                                    onbehalf_request =  {false}
                                                    vendor_id = {null}
                                                    vendorsite_id = {null}
                                                    hideBillingRule={true}
                                                />
                                                </div>
                                                : <SimpleLoading/>
                                            }
                                        </div>
                                    </>
                                }
                                rightSideContent={this.addComponentRight.bind(this)}
                            /> 
                        </div>
                    </div>
                    {this.state.saveBtnApi || this.state.isPosting ? <OverlayLoader isLogoCenter={true} /> : null}
                </MasterComonent>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        language: state.language
    };
};

const SecurityOptions = {
    gui_id: Gui_id_list.receiving.goods_receiving.goods_receiving_main_edit_screen
};

export default connect(
    mapStateToProps
) ((RsWithRouter(ApplySecurityRoles(EditGoodsReceivingNote, SecurityOptions))));