import React, { useEffect, useState, useRef} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Helper from "../../../inc/Helper";
import SwitchViewDynamic from "../../../inc/SwitchViewDynamic";
import Button from "../../inc/Button";
import NavigationHeder from "../../Navigations/NavigationHeder";
import printIcon from "../../../assets/print_icon.svg";
import BlueCallToAction from "../../Widgets/BlueCallToAction/BlueCallToAction";
import MasterSidebar from "../../Sidebars/MasterSidebar";
import NoteAttachmentHistory from "../../Widgets/NoteAttachmentHistory";
import Collapse from "../../inc/Collapse";
import RFQCommonApiCalls from "../RFQCommonApiCalls";
import GeneralInformationForm from "./HeaderView/GeneralInformationForm";
import Controls from "./HeaderView/Controls";
import ContractTerms from "./HeaderView/ContractTerms";
import VendorsGrid from "./HeaderView/VendorsGrid";
import CollaborationTeamGrid from "./HeaderView/CollaborationTeamGrid";
import LineViewComponent from "../../Purchase Orders Internal/Edit PO/ListView/LineViewComponent";
import Api from "../../../inc/Api";
import Settings from "../../../inc/Settings";
import ScoringCriteria from "./HeaderView/ScoringCriteria";
import SegmentsDistribution from "../../Segments/SegmentsDistribution";
import PublishRFQModal from "./PublishRFQModal";
import TechnicalEvaluationWithConsolidatedView from "../../External Screens/Request For Quotation/Team Evaluation/TechnicalEvaluationWithConsolidatedView";
import ExtensionModal from "./ExtensionModal";
import RetenderModal from "./RetenderModal";
import CommercialEvaluation from "./CommercialEvaluation/CommercialEvaluation";
import RFQAttachmentPopup from "../../External Screens/RFQ Vendor Reply/RFQList/RFQAttachmentPopup";
import RFQAwardingScreen from "../RFQAwardingScreen";
import FixedNavigationHeder from "../../Navigations/FixedNavigationHeder";
import TabContentWraper from "../../Widgets/Tabs/TabContentWraper";
import DatepickerFunctions from "../../../inc/DatepickerHelper";
import PublishedHistoryModal from "./PublishedHistoryModal";
import { ButtonSkeletonComponent, TextSkeletonComponent } from "../../Skeleton/SkeletonComponent";
import ComplianceTemplates from "../../External Screens/Request For Quotation/Compliances/ComplianceTemplates";
import { checkIfArrayIsEmpty, checkInputFieldsSliderValues, checkLineRecommendations, checkLinesEitherAwardedPreferred, checkTextAreasHavingValues, getPopupMessageBasedOnStatus, getSourceIdForWorkflowTrigger, isEmptyObject} from "../../../inc/Validation";
import CompliancesConsolidatedView from "../../External Screens/Request For Quotation/Compliances/CompliancesConsolidatedView";
import MasterComponentWraper from "../../Backend/MasterComponentWraper";
import AlertNew from "../../inc/Alert";  
import { Alert } from "react-bootstrap";
import AddressList from "../../Tenant/Address/AddressList";
import ContactAddress from "../../Tenant/Address/ContactAddress";
import EvaluationOperations from "./EvaluationOperations";
import $ from "jquery"
import ExtensionRequests from "./ExtensionRequests";
import FormValidator from "../../../inc/FormValidator";
import TemplateModal from "./TemplateModal";
import UnsealedBidModal from "./UnsealedBidModal";
import WorkFlowCommonHeader from "../../Header/WorkFlowCommonHeader";
import WorkFlowCommonHeaderAPICalls from "../../Header/WorkflowCommonHeaderAPICalls";
import BAFOModal from "./BAFOModal";
import WorkFlowOffCanvas from "../../Header/WorkFlowOffCanvas"; 
import axios from 'axios'
import OverlayLoader from "../../PurchaseRequisition/common/OverlayLoader"
import ApplySecurityRoles from "../../SecurityRoles/ApplySecurityRoles";
import Gui_id_list from "../../../inc/Gui_id_list";
import RFQAuctionInnerPopup from "../../External Screens/RFQ Vendor Reply/RFQBiddingDetails/RFQAuctionInnerPopup";
import LinkBtn from "../../inc/LinkBtn";

let executeRequestRetenderBtn = null;
let executeFunctionTaskActionApi = null;
let cancelTokenSource = axios.CancelToken.source();
let lineObj = null

const RFQDetailForm = (props) => {
 
  const ViewHeaderList = [
    Helper.getLabel(props.language, "header_view", "Header View"),
    Helper.getLabel(props.language, "line_view", "Line View"),
    Helper.getLabel(
      props.language,
      "technical_evaluation",
      "Technical Evaluation"
    ),
    Helper.getLabel(
      props.language,
      "commercial_evaluation",
      "Commercial Evaluation"
    ),
    Helper.getLabel(
      props.language,
      "consolidated_view_compliance",
      "Compliance Consolidated"
    ),
    Helper.getLabel(props.language, "awarding", "Awarding"),
  ];

  const ViewHeaderListTechnicalEvaluationPreview = [
    Helper.getLabel(props.language, "header_view", "Header View"), 
    Helper.getLabel(
      props.language,
      "technical_evaluation",
      "Technical Evaluation"
    )
  ];

  let ViewHeaderListTechnical = [
    Helper.getLabel(
      props.language,
      "technical_evaluation",
      "Technical Evaluation"
    ),
  ];

  let ViewHeaderListWithoutTechComm = [
    Helper.getLabel(props.language, "header_view", "Header View"),
    Helper.getLabel(props.language, "line_view", "Line View"),
  ];


  let viewWithoutRFQAccess

  const commonApiCalls = new RFQCommonApiCalls();
  const workflowCalls = new WorkFlowCommonHeaderAPICalls();

  const location = useLocation();
  const [viewComponent, setViewComponent] = useState(ViewHeaderList[0]);
  const [rfqHeader, setRfqHeader] = useState(null);
  const [disableFormComponent, setDisableFormComponent] = useState(false);
  const [isHeaderLoading, setIsHeaderLoading] = useState(false);
  const [rfqPublishDate, setRfqPublishDate] = useState(null);
  const [rfqExpiryDate, setRfqExpiryDate] = useState(new Date(Date.now() + (3600 * 1000 * 24)));
  const [employeesList, setEmployeesList] = useState([]); 
  const [securityLevelList, setSecurityLevelList] = useState([]);
  const [apiCallStateWF, setApiCallStateWF] = useState(false);
  const [quotationTypeList, setQuotationTypeList] = useState([]);
  const [paymentTermList, setPaymentTermList] = useState([]);
  const [workflowTaskActions, setWorkflowTaskActions] = useState([]);
  const [rfqScores, setRFQScores] = useState({});
  const [modeOfDeliveryList, setModeOfDeliveryList] = useState([]);
  const [deliveryTermList, setDeliveryTermList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [oldCollaborationTeams, setOldCollaborationTeams] = useState([]);
  const [collaborationTeams, setCollaborationTeams] = useState([]);
  const [vendorGridData, setVendorGridData] = useState([]);
  const [updateVendorGridData, setUpdateVendorGridData] = useState(false);
  const newRows = useRef([]);
  const updateVendorRow = useRef([]); 
  const [updateRFQ, setUpdateRFQ] = useState(null);
  const [publishModal, setPublishModal] = useState(false);
  const [publishedHistoryModal, setPublishedHistoryModal] = useState(false);
  const [extensionModal, setExtensionModal] = useState(false);
  const [retenderModal, setRetenderModal] = useState(false);
  const [updatePageUI, setUpdatePageUI] = useState(false);
  const [allRfqStatus, setAllRfqStatus] = useState([]);
  const [refreshAfterPublish, setRefreshAfterPublish] = useState(false);
  const [tags, setTags] = useState();
  const [showModal, setShowModal] = useState(false);
  const [popUpPosition, setPopUpPosition] = useState({ top: 0, right: 0 });
  const [rfqAttachments, setRFQAttachments] = useState([]);
  const navigate = useNavigate();
  const [scoreRfqEvaluatorId, setScoreRfqEvaluatorId] = useState();
  const [isSubmittedTechnical, setIsSubmittedTechnical] = useState(false);
  const [reasonForSelection, setReasonForSelection] = useState('');
  const [evaluatorData,setEvaluatorData]=useState({}) 
  const [technicalScoreSummary, setTechnicalScoreSummary] = useState([]);
  const [scoreSummaryCommercial, setScoreSummaryCommercial] = useState([]);
  const [componentTypeRendered, setComponentTypeRendered] = useState({});
  const [rfqScoresState,setRFQScoresState]=useState({})
  const [evaluationOperationModal,setEvaluationOperationModal]=useState(false)
  const [unsealedOperationModal,setUnsealedOperationModal]=useState(false)
  const [extensionRequestsPopup,setExtensionRequestsPopup]=useState(false)
  const [targetDocuments,setTargetDocuments]=useState([])
  const [buyerList,setBuyerList]=useState([])
  const [rfqStages,setRfqStages]=useState([])
  const [show, setShow] = useState(true);
  const [templatesPopup,setTemplatePopup]=useState(false)
  const [hideSidebarState, setHideSidebarState] = useState(false);
  const [numberSequence,setNumberSequence]=useState({})
  const [taxGroup,setTaxGroup]=useState([])
  const [taxGroupItem,setTaxGroupItem]=useState([])
  const [actionHistorySourceId, setActionHistorySourceId] = useState(null);
  const [bafoPopup,setBafoPopup]=useState(false)
  const [bafoBidType,setBafoBidType] = useState('')
  const [underWorkflow, setUnderWorkflow] = useState(false);
  const [auctionTypeList, setAuctionTypeList] = useState([]);
  const [timeTypes,setTimeTypes]=useState([])
  const [biddingDetailsPopup,setBiddingDetailsPopup]=useState(false)

  // Workflow States
  const [workflowActionHistory, setWorkflowActionHistory] = useState([]);
  const [workflowEligible, setWorkflowEligible] = useState(null);
  const [workflowExist, setWorkflowExist] = useState({}); 

  const [showCanvas, setShowCanvas] = useState(false);
  const [showCurrentAssignees, setShowCurrentAssignees] = useState(false);
  const [saveBtn,setSaveBtn]=useState(false)
  const [actionHistoryLoadingState, setActionHistoryLoadingState] = useState(false);

 //line-attachment-state
  const [lineId, setLineId] = useState(0)
  const [attachmentSourceId, setAttachmentSourceId] = useState(null)

  let navs = [
    {
      title: Helper.getLabel(props.language, "general", "General"),
      data_section: "one", active_status: 'active_tab',
    },
    { title: Helper.getLabel(props.language, "vendors", "Vendors"), data_section: "two", active_status: '' },
    {
      title: Helper.getLabel(
        props.language,
        "collaboration_team",
        "Collaboration Team"
      ),
      data_section: "three", active_status: ''
    },
    {
      title: Helper.getLabel(props.language, "segment", "Segment"),
      data_section: "four", active_status: ''
    },
    {
      title: Helper.getLabel(
        props.language,
        "scoring_criteria",
        "Scoring Criteria"
      ),
      data_section: "five", active_status: ''
    },
    {
      title: Helper.getLabel(props.language, "controls", "Controls"),
      data_section: "six", active_status: ''
    },
    {
      title: Helper.getLabel(
        props.language,
        "compliance_transactional",
        "Compliances"
      ),
      data_section: "seven", active_status: ''
    },
    {
      title: Helper.getLabel(
        props.language,
        "terms_conditions",
        "Terms & Conditions"
      ),
      data_section: "eight", active_status: ''
    },
    {
      title: Helper.getLabel(
        props.language,
        "address",
        "Address"
      ),
      data_section: "nine", active_status: ''
    },
    {
      title: Helper.getLabel(
        props.language,
        "contact",
        "Contact"
      ),
      data_section: "ten", active_status: ''
    },
  ];

  if(rfqHeader?.rfq_type===2){
    navs = navs.filter((item)=>item.title !== Helper.getLabel(props.language,"scoring_criteria", "Scoring Criteria")) 
  }

  const getSearchParam = (name) => {
    const params = new URLSearchParams(location.search);
    return params.get(name);
  };
  const [selectedSupNav, setSelectedSupNav] = useState('Header View')
  const [scrollDitection, setScrollDitection] = useState('down')
  const [height, setHeight] = useState(0)

  const myDivRef = useRef(null);

  let TechnicalEvaluationPreview = getSearchParam('technical_evaluation') ? true : false;
  let userComingFromDashboard = getSearchParam('common_user_access') === 'true';
  let sectionName = 'request-quotation-edit'
   
  let validator 
  
  useEffect(() => { 
    Helper.resizeWindowHandle(myDivRef,setHeight,window) 
  }, [myDivRef?.current?.clientHeight,scrollDitection,selectedSupNav,show]);
   
  useEffect(()=>{
    getRFqHeaderData()
  },[updatePageUI,refreshAfterPublish])

  useEffect(() => {
    getActionHistoryData()
  }, [actionHistorySourceId])
  
  useEffect(() => {
    getAsyncData();
      getCollabTeamAsyncData();
  }, []);

  useEffect(()=>{
    if(rfqHeader?.rfq_status){
      getWorkFlowCalls();
    }
  },[rfqHeader,rfqHeader?.rfq_status])

  useEffect(() => {
    getTags();
  }, [allRfqStatus,rfqHeader, refreshAfterPublish]);

  useEffect(() => {
    getRfqStatus();
    getTargetDocuments()
  },[]);
 
  const handleClose = () => setShow(false);
  
  const validationConfigure = () => {
    let fieldConfig = [ 
      {name:'document_number', displayName:Helper.getLabel(props.language,'document_number','Document Number'),types:['Required'], max: 80},
      {name:'title', displayName:Helper.getLabel(props.language,'title','Title'),types:['Required'], max: 255},  
      {name:'expiry_datetime', displayName:Helper.getLabel(props.language,'expiry_datetime','Expiry Date Time'),types:['Required'], max: 80},
      // {name:'security_level', displayName:Helper.getLabel(props.language,'security_level','Security Level'),types:['Required'], max: 80},
      {name:'quotation_type', displayName:Helper.getLabel(props.language,'quotation_type','Quotation Type'),types:['Required']},
      {name:'currency_id', displayName:Helper.getLabel(props.language,'currency','Currency'),types:['Required'], max: 80},
      {name:'site_id', displayName:Helper.getLabel(props.language,'site','Site'),types:['Required'], max: 80},
      {name:'warehouse_id', displayName:Helper.getLabel(props.language,'warehouse','Warehouse'),types:['Required'], max: 80},
      {name:'email_address', displayName:Helper.getLabel(props.language,'email','Email'),types:['Email'], max: 100},
    ]
    if(parseInt(rfqHeader?.target_document)===1){
       fieldConfig = fieldConfig?.concat([
        {name: 'start_date', displayName: Helper.getLabel(props.language, 'start_date', 'Start date'), types: ['Required']},
        {name: 'end_date', displayName: Helper.getLabel(props.language, 'end_date', 'End date'), types: ['Required']},
    ]);
    }
    if(rfqHeader?.rfq_type===2){
      fieldConfig = fieldConfig?.filter((item)=>item.displayName !== Helper.getLabel(props.language,'expiry_datetime','Expiry Date Time'))?.concat([
        {name: 'auction_start_date_time', displayName: Helper.getLabel(props.language, 'auction_start_date_time', 'Auction start date time'), types: ['Required']},
        {name: 'auction_end_date_time', displayName: Helper.getLabel(props.language, 'auction_end_date_time', 'Auction end date time'), types: ['Required']},
    ]);
    }
    if(rfqHeader?.rfq_type===2 && parseInt(rfqHeader?.auction_type)===1){
      fieldConfig.push({name:'auction_bid_to_range',displayName: Helper.getLabel(props.language, 'upper _cap', 'Upper cap'), types: ['Required']},)
    }
    if(!validator) {
      validator = new FormValidator(fieldConfig,props.language)
    }
  }

  validationConfigure()
  const handleRfQHeaderUpdate = async () => {
    try {
      const paylods= {
        ...updateRFQ,
      }
      let validData = {
        ...rfqHeader
      }
      if(!validator.isValid(validData)){
        validator.displayMessage();
        validator.reset() 
        return;
      } 
 
    if (paylods.quotation_type_id === 0 || paylods.quotation_type_id) {
      paylods.quotation_type = paylods.quotation_type_id;
      delete paylods.quotation_type_id;
    }
    if (paylods.discount_amount=== "") {
      paylods.discount_amount=0
    }

    if (paylods.discount_percentage=== "") {
      paylods.discount_percentage=0;
    }

    if (paylods.rounding_amount=== "") {
      paylods.rounding_amount=0;
    }

    if(paylods.auction_bid_to_range){
        paylods.auction_bid_to_range = parseFloat(paylods.auction_bid_to_range)
    }

    if(paylods.automatic_time_extensions_type){
      paylods.automatic_time_extensions_type = parseInt(paylods.automatic_time_extensions_type)
    }

    if(paylods.automatic_time_extensions){
      paylods.automatic_time_extensions = parseInt(paylods.automatic_time_extensions)
    }

    if(paylods.price_decrease){
      paylods.price_decrease = parseFloat(paylods.price_decrease)
    }

      const updateRfqvvRes = await commonApiCalls.updateRfqHeader(rfqHeader?.RFQ_id, {
        ...paylods,
      },{ cancelToken: cancelTokenSource.token });

      if (updateRfqvvRes) {
        Helper.alert(updateRfqvvRes?.message, "success");
        setUpdateRFQ({})
      }
  } catch (error) {
    // Handle the error here, you can log it or show a user-friendly message
    getPopupMessageBasedOnStatus(error)
  }
  }
  const handleSaveClick = () => {
    Helper.createDebouncedAPIFunction([async ()=>{return await handleRfQHeaderUpdate()}], setSaveBtn, cancelTokenSource, false, 300000)();
  };

  const getRFqHeaderData = async () => {
    let rfqHeaderData = null
    let api = Api
    api.setUserToken()
    setIsHeaderLoading(true)
    try {
        rfqHeaderData = await commonApiCalls.getRFQHeaderByID(
          getSearchParam('rfq_view_id')
        )
      if (rfqHeaderData) {
        setRfqHeader(rfqHeaderData)
        let numberSequenceData = null;
        
        try {
          numberSequenceData = await api.axios().get(Settings.apiUrl + `/ns_status/${rfqHeaderData?.entity_id}/${rfqHeader?.rfq_type===2?Settings.source_id.auction:Settings.source_id.request_for_quotation}`);
          setNumberSequence(numberSequenceData?.data)
        } catch (error) {
          console.error('Failed to fetch number sequence data:', error);
        }
        setIsHeaderLoading(false)
      } else {
        setIsHeaderLoading(false)
        setRfqHeader(rfqHeaderData)
      }
      setRfqPublishDate(rfqHeaderData?.publish_datetime)
      setRfqExpiryDate(rfqHeaderData?.expiry_datetime)
      getActionHistoryData()
    } catch (err) {
      setIsHeaderLoading(false)
      getActionHistoryData()
      console.log(err)
    }
  }

  const getAsyncData = async () => {
  // here we check and store localStore data into variable...
    try {
      const employeesData = await commonApiCalls.getAllEmployees();
      const currenciesData = await commonApiCalls.getAllCurrencies();
      const securityLevelData = await commonApiCalls.getRfqSecurityLevels();
      const quotationTypeData = await commonApiCalls.getRfqQuotationTypes();
      const paymentTermsData = await commonApiCalls.getPaymentTerms();
      const modeOfDeliveryData = await commonApiCalls.getModeOfDelivery();
      const deliveryTermsData = await commonApiCalls.getDeliveryTerms();
      const buyersListData = await commonApiCalls.getAllBuyers()
      const rfqStagesList = await commonApiCalls.getAllRfqStages()
      const taxGroupListData = await commonApiCalls.getAllTaxGroups()
      const taxGroupItemListData = await commonApiCalls.getAllTaxGroupItems()
      const auctionTypeData = await commonApiCalls.getAuctionTypes()
      const automaticTimeTypesData = await commonApiCalls.getAutomaticTimeTypes()

      setEmployeesList(employeesData);
      setCurrencyList(currenciesData);
      setSecurityLevelList(securityLevelData);
      setQuotationTypeList(quotationTypeData);
      setPaymentTermList(paymentTermsData);
      setModeOfDeliveryList(modeOfDeliveryData);
      setDeliveryTermList(deliveryTermsData);
      setBuyerList(buyersListData)
      setRfqStages(rfqStagesList)
      setTaxGroup(taxGroupListData)
      setTaxGroupItem(taxGroupItemListData) 
      setAuctionTypeList(auctionTypeData)
      setTimeTypes(automaticTimeTypesData)
    } catch (err) {
      console.log(err);
    }
  };

  const getCollabTeamAsyncData = async () => {
    try {
      if (getSearchParam('rfq_view_id')) {
        const teamsData = await commonApiCalls.getAllTeamSourceLines(
          rfqHeader?.rfq_type===2 || location?.state?.rfqType===2?Settings?.source_id?.auction:Settings?.source_id?.request_for_quotation,
          getSearchParam('rfq_view_id')
        );
        if (teamsData) {
          setCollaborationTeams(teamsData);
          setOldCollaborationTeams(teamsData);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getActionHistoryData = async () => {
    if(!actionHistorySourceId) return;

    setActionHistoryLoadingState(true);
    const workflowActionHistory = await workflowCalls.getWorkFlowActionHistory(actionHistorySourceId, getSearchParam("rfq_view_id"));
    if(workflowActionHistory) setWorkflowActionHistory(workflowActionHistory);
    setActionHistoryLoadingState(false);
  };

  const getWorkFlowCalls = async () => {
    const trigger = await workflowCalls.getWorkFlowTriggers(); 
    const mode = !checkIfArrayIsEmpty(trigger) && trigger?.find(id => id.name == "Update")?.id;

    let workflowSourceId = getSourceIdForWorkflowTrigger(rfqHeader?.rfq_status,rfqHeader?.rfq_type)

    const workflowExist = await workflowCalls.getWorkFlowInstanceExist(props.auth?.user?.tenant_id, workflowSourceId?.value, getSearchParam("rfq_view_id"));  
    const eligibleWorkflow = await workflowCalls.checkWorkFlowForSpecificModule(workflowSourceId?.value, props.auth?.user?.tenant_id, mode); 
 
    if(workflowExist) setWorkflowExist(workflowExist);
    if(eligibleWorkflow) setWorkflowEligible(eligibleWorkflow);
  }

  const workFlowReloadFunction = () => { 
    getWorkFlowCalls() 
    getRFqHeaderData()
  }

  const getRfqStatus = () => {
    const api = Api;
    api.setUserToken();
    api
      .axios()
      .get(`${Settings.loginUrl}/enum/RFQ_status`)
      .then((res) => {
        setAllRfqStatus(res?.data?.data);
      })
      .catch((error) => console.log("Error in RFQ_status: ", error));
  };

  const getTargetDocuments = () => {
    const api = Api;
    api.setUserToken();
    api
      .axios()
      .get(Settings.loginUrl + `/enum/pr_targetdocumenttype`)
      .then((res) => {
        const filteredData = res?.data?.data.filter(item => item.name === 'Purchase order' || item.name === 'Contract');
        setTargetDocuments(filteredData);
      })
      .catch((error) => console.log("Error in RFQ_status: ", error));
  };

  const getRFQStatusNameById = () => {
    let rfq_status = null;
    if (getSearchParam("rfq_view_id")) {
      rfq_status = rfqHeader?.rfq_status;
    }
    const status = allRfqStatus?.find((el) => el.id === rfq_status);
    return status?.name;
  };

  let teamData = collaborationTeams?.find((item)=>item?.employee_id===props.auth?.user?.employee_id)

 const isAllTabsVisible = () => {
   return getSearchParam("rfq_view_id") && props?.auth?.user?.is_buyer 
 } 

 const getAdditionalTabs = (teamData,viewWithoutRFQAccess) => {
  if(userComingFromDashboard && !teamData && (location?.state?.module_id === Settings.source_id.rfq_technical_evaluation)){
    viewWithoutRFQAccess.push('Technical Evaluation')
   }
   if(userComingFromDashboard && !teamData && (location?.state?.module_id === Settings.source_id.rfq_financial_evaluation || location?.state?.module_id === Settings.source_id.auction_commercial)){
    viewWithoutRFQAccess.push('Commercial Evaluation')
   }
   if (teamData?.perform_RFQ_technical_evaluation && rfqHeader?.rfq_type !== 2){
      viewWithoutRFQAccess.push('Technical Evaluation')
    } 
    if (teamData?.perform_RFQ_financial_evaluation){
       viewWithoutRFQAccess.push('Commercial Evaluation')
    }
    viewWithoutRFQAccess.push('Awarding')
 }

  const switchViewDynamicSelector = () => { 
    let viewComponentElement; 
    if (TechnicalEvaluationPreview) {
      viewComponentElement = (
        <SwitchViewDynamic
          viewComponent={viewComponent}
          setViewComponent={setViewComponent}
          headerList={ViewHeaderListTechnicalEvaluationPreview}
        />
      );
      return viewComponentElement;
    }
    if (isAllTabsVisible()
      ) {
     let supeNavData = rfqHeader?.rfq_type ===2 ? ViewHeaderList?.filter((item)=>item!=="Technical Evaluation") : ViewHeaderList
      viewComponentElement = (
        <SwitchViewDynamic
          viewComponent={viewComponent}
          setViewComponent={setViewComponent}
          headerList={supeNavData}
        />
      );
      return viewComponentElement;
    }
    else if (teamData?.RFP_view_access && (!props?.auth?.user?.is_buyer && teamData?.access_level===1)) {
        viewWithoutRFQAccess = ViewHeaderListWithoutTechComm
        getAdditionalTabs(teamData,viewWithoutRFQAccess)

        viewComponentElement = (
          <SwitchViewDynamic
            viewComponent={viewComponent}
            setViewComponent={setViewComponent}
            headerList={viewWithoutRFQAccess}
          />
        );
      return viewComponentElement;
    }
    else if (!teamData?.RFP_view_access) {
      viewWithoutRFQAccess = [Helper.getLabel(props.language, "header_view", "Header View"),]
      getAdditionalTabs(teamData,viewWithoutRFQAccess)
      viewComponentElement = (
        <SwitchViewDynamic
          viewComponent={viewComponent}
          setViewComponent={setViewComponent}
          headerList={viewWithoutRFQAccess}
        />
      );
    return viewComponentElement;
  }
  };

   
  const userRoleCondition = !props?.auth?.user?.is_buyer 
  const disableCondition = rfqHeader?.rfq_status !== 0 &&  rfqHeader?.rfq_status !== 1 &&  rfqHeader?.rfq_status !== 9 

  const getTags = () => {
    let tags = [
      {
        title: Helper.getLabel(
          props.language,
          getRFQStatusNameById()?.toLowerCase(),
          getRFQStatusNameById()
        ),
        type: "error",
      },
    ];
    setTags(tags);
  };
  const addComponentTop = () => {
    let items = [
      {
        title: Helper.getLabel(props.language, "rfq_number", "RFQ Number"),
        subtitle: !rfqHeader ? (
          <TextSkeletonComponent />        
        ) : rfqHeader?.document_number || "-",
      },
      {
        title: Helper.getLabel(
          props.language,
          "document_type",
          "Document Type"
        ),
        subtitle: !rfqHeader ? (
          <TextSkeletonComponent />
        ) : rfqHeader?.quotation_type ?? "-",
      },
      {
        title: Helper.getLabel(
          props.language,
          "title",
          "RFQ Title"
        ),
        subtitle: !rfqHeader ? (
          <TextSkeletonComponent />
        ) : (rfqHeader?.title?.length > 30) ? (
          rfqHeader?.title?.substring(0, 30) + "..." || "-"
        ) : rfqHeader?.title || "-",
      },
      {
        title: Helper.getLabel(props.language, "publish_Date", "Publish Date and Time"),
        subtitle: !rfqHeader ? (
          <TextSkeletonComponent />
        ) : DatepickerFunctions.convertDateTimeFromDataBase(rfqPublishDate) || "-",
      },
      {
        title: Helper.getLabel(
          props.language,
          "expiry_date",
          "Expiry Date and Time"
        ),
        subtitle: !rfqHeader ? (
          <TextSkeletonComponent />
        ) : DatepickerFunctions.convertDateTimeFromDataBase(rfqExpiryDate) || "-" },
    ];

    if(rfqHeader?.rfq_type===2){
      items = items.filter((item)=>item.title !== Helper.getLabel(props.language, "expiry_date", "Expiry Date and Time"))
    }
   
    return (
      <div className={`rfq_detail_form`}>
        <div className="rfq-internal-blue">
          <BlueCallToAction
            scrollDitection={scrollDitection}
            items={items}
            tags={tags}
          />
        </div>
      </div>
    )
  };

  const getIntegratorId = () => {
    if (getSearchParam("rfq_view_id")) {
      return getSearchParam("rfq_view_id");
    }
  };

  const addComponentRight = () => {
    return (
      <div className='parent-sidebar-cn'>
        <MasterSidebar hideSidebarState={setHideSidebarState}>
          <NoteAttachmentHistory
            openCanvas={setShowCanvas}
            openCurrentAssignees={setShowCurrentAssignees}
            scrollDitection={scrollDitection}
            historyArray={workflowActionHistory}
            height={height - 30}
            actionHistoryLoadingState={actionHistoryLoadingState} 
            setActionHistoryState={setActionHistorySourceId}
            showActionHistoryDropdown={true}
            actionHistoryForRFQ={rfqHeader?.rfq_type !== 2}
            source_id={rfqHeader?.source_id}
            notesource_integrator={getIntegratorId()}
            attachmentsource_integrator={getIntegratorId()}
          />
        </MasterSidebar>

        {showCanvas &&
          <WorkFlowOffCanvas
            isOpen={showCanvas} 
            type={{ label: "Action History", value: 9 }}
            closeSidebar={setShowCanvas}
            source_id={actionHistorySourceId}
            source_item_id={getSearchParam("rfq_view_id")}
          />
        }

        {showCurrentAssignees && 
          <WorkFlowOffCanvas
            isOpen={showCurrentAssignees} 
            type={{ label: "View current assignment", value: 43 }}
            closeSidebar={setShowCurrentAssignees}
            source_id={actionHistorySourceId}
            source_item_id={getSearchParam("rfq_view_id")}
          />
        }
      </div>
    );
  };

  const handlePublishClick = () => {
    setPublishModal(true);
  };

  // this function for open PublishedHistory modal
  const handlePublishedHistoryHandler = () => {
    setPublishedHistoryModal(true);
  }

  const onExtension = () => {
    setExtensionModal(true);
  };

  const onRetender = () => {
    setRetenderModal(true);
  };

  const handleHoldOperation = async (type) => {
    if (!rfqHeader) return;
    let payload = {
      onhold: type==="hold"  
    }
    setIsHeaderLoading(true)
    try{
      const updateRfqvvRes = await commonApiCalls.updateRfqHeader(rfqHeader?.RFQ_id, {
        ...payload,
      });

      if (updateRfqvvRes) {
        Helper.alert(updateRfqvvRes?.message, "success");
        setRfqHeader({...rfqHeader,onhold:payload?.onhold})
        setIsHeaderLoading(false)
      }
    }catch (error) {
      setIsHeaderLoading(false)
      getPopupMessageBasedOnStatus(error)
    }
  }

  const handleCancelOperation = async () => {
    if (!rfqHeader) return;
    let payload = {
      rfq_status:2 
    }
    setIsHeaderLoading(true)
    try{
      const updateRfqvvRes = await commonApiCalls.updateRfqHeader(rfqHeader?.RFQ_id, {
        ...payload,
      });

      if (updateRfqvvRes) {
        Helper.alert(updateRfqvvRes?.message, "success");
        setRfqHeader({...rfqHeader,rfq_status:payload?.rfq_status})
        setIsHeaderLoading(false)
      }
    }catch (error) {
      setIsHeaderLoading(false)
      getPopupMessageBasedOnStatus(error)
    }
  }

  const holdOperationHandler = (type) => {
    Helper.confirmMe(() => {
      handleHoldOperation(type)
    },type==="hold"?"Your RFQ will be on hold. Any change in RFQ will not be consider.":"Your RFQ will be moved out of the hold state.", null,type==="hold" ? `Are you sure you want to hold this RFQ?`:`Are you sure you want to withdraw this RFQ from hold?`,type==="hold"?400:520)
  }

  const cancelOperationHandler = (type) => {
    Helper.confirmMe(() => {
      handleCancelOperation()
    },"Your RFQ will be canceled.you have to re-tender it for further amendments.", null, `Are you sure you want to cancel this RFQ?`,420)
  }

  const handleShowGraph = () => {
    navigate(`/graph?rfq_view_id=${getSearchParam("rfq_view_id")}`);
  };

  const isButtonDisable = (name) => {
    let buttonArray = []
    if(rfqHeader?.rfq_status === 0){
      buttonArray.push('initiate technical','recall technical','initiate commercial','recall commercial','initiate awarding','recall awarding','evaluation operations','initiate bafo','return bid')
    }else if((rfqHeader?.rfq_status === 1 && new Date(rfqHeader?.expiry_datetime) < new Date())  && parseInt(rfqHeader?.rfq_stages_id)!==1){
      buttonArray.push('recall technical','initiate commercial','recall commercial','initiate awarding','recall awarding','evaluation operations')
    }
    else if((rfqHeader?.rfq_status === 1 && new Date(rfqHeader?.expiry_datetime) < new Date()) && parseInt(rfqHeader?.rfq_stages_id)===1){
      buttonArray.push('initiate technical','recall technical','initiate awarding','recall awarding','evaluation operations')
    }
    else if(rfqHeader?.rfq_status===3 || rfqHeader?.rfq_status===9){
      buttonArray.push('initiate technical','recall technical','initiate commercial','recall commercial','initiate awarding','recall awarding','initiate bafo','return bid')
    }else if(rfqHeader?.rfq_status===5){
      buttonArray.push('initiate technical','recall commercial','initiate awarding','recall awarding','initiate bafo','return bid')
    }else if(rfqHeader?.rfq_status ===6){
      buttonArray.push('initiate technical','recall technical','initiate commercial','recall awarding','initiate bafo','return bid')
    }else if(rfqHeader?.rfq_status ===7){
      buttonArray.push('initiate technical','recall technical','initiate commercial','recall commercial','initiate awarding','initiate bafo','return bid')
    }
    return buttonArray?.includes(name) 
  }
 
  const moreOptions = () => {
    const isOptionsDisabled = rfqHeader?.onhold || rfqHeader?.rfq_status===2 || rfqHeader?.rfq_status===3
    return (
      <div className="rfq_moreOptions">
        {getSearchParam("rfq_view_id") && (
          <button onClick={()=>{setTemplatePopup(true)}}>
             Print
          </button>
         )}
        {getSearchParam("rfq_view_id") && canPerformEvaluation('hold') && (
          <button onClick={()=>{holdOperationHandler('hold')}} disabled={isOptionsDisabled}>
             Hold
          </button>
        )}
        {getSearchParam("rfq_view_id") && canPerformEvaluation('withdrew hold') && (
          <button onClick={()=>{holdOperationHandler('withdraw hold')}} disabled={rfqHeader?.rfq_status===2 || rfqHeader?.rfq_status===3 || !rfqHeader?.onhold}>
            Withdraw hold
          </button>
        )}
        {getSearchParam("rfq_view_id") && canPerformEvaluation('cancel') && (
          <button onClick={cancelOperationHandler} disabled={isOptionsDisabled}>
             Close
          </button>
        )}
        {getSearchParam("rfq_view_id") && rfqHeader?.rfq_type !== 2 && (
          <button onClick={()=>{setUnsealedOperationModal(true)}} disabled={isOptionsDisabled || rfqHeader?.quotation_type!=="Sealed" || rfqHeader?.rfq_status === 0}>
             Intiate Unseal
          </button>
        )}
        {getSearchParam("rfq_view_id")  && rfqHeader?.rfq_type !== 2 && (
          <button onClick={handleInitiateBAFOClick} disabled={isButtonDisable('initiate bafo') || (!vendorGridData?.some(vendor => vendor.vendor_replystatus === 2)) } >
            Initiate BAFO
          </button>
        )}
        {getSearchParam("rfq_view_id") && rfqHeader?.rfq_type !== 2  && (
          <button onClick={handleReturnBidClick} disabled={isButtonDisable('return bid')}>
             Return Bid
          </button>
        )}
        {getSearchParam("rfq_view_id") && canPerformEvaluation('technical') &&  (
          <button onClick={handleInitiateEvaluationClick} disabled={isButtonDisable('initiate technical')}>
            Initiate Technical Evaluation
          </button>
        )}
         {getSearchParam("rfq_view_id") && canPerformEvaluation('technical') && (
          <button onClick={()=>{handleRecallEvaluationClick('technical evaluation')}} disabled={isButtonDisable('recall technical')}>
            Recall Technical Evaluation
          </button>
        )}
        {getSearchParam("rfq_view_id") && canPerformEvaluation('financial') && (
          <button onClick={handleInitiateCommercialEvaluationClick} disabled={isButtonDisable('initiate commercial')}>
            Initiate Commercial Evaluation
          </button>
        )}
         {getSearchParam("rfq_view_id") && canPerformEvaluation('financial') && (
          <button onClick={()=>{handleRecallEvaluationClick('commercial evaluation')}} disabled={isButtonDisable('recall commercial')}>
            Recall Commercial Evaluation
          </button>
        )}
         {getSearchParam("rfq_view_id") && canPerformEvaluation('awarding') && (
          <button onClick={handleInitiateAwardingClick} disabled={isButtonDisable('initiate awarding')}>
            Initiate Awarding
          </button>
        )}
         {getSearchParam("rfq_view_id") && canPerformEvaluation('awarding') && (
          <button onClick={()=>{handleRecallEvaluationClick('awarding')}} disabled={isButtonDisable('recall awarding')||rfqHeader?.rfq_status===3}>
            Recall Awarding
          </button>
        )}
         {getSearchParam("rfq_view_id") && (
          <button onClick={()=>{setEvaluationOperationModal(true)}} disabled={isButtonDisable('evaluation operations')}>
            Evaluation Operations
          </button>
        )}
        {getSearchParam("rfq_view_id") &&  rfqHeader?.rfq_type !== 2 && (
          <button onClick={()=>{setExtensionRequestsPopup(true)}} disabled={rfqHeader?.rfq_status === 0}>
            Extension Requests
          </button>
        )}
         {getSearchParam("rfq_view_id") && rfqHeader?.rfq_type ===2 && (
          <button onClick={()=>{setBiddingDetailsPopup(true)}}>Competitor Bidding Details</button>
        )}
        {getSearchParam("rfq_view_id") && (
          <button onClick={handleShowGraph} disabled={rfqHeader?.rfq_status === 0 ||  rfqHeader?.rfq_status ===9}>Compare Vendors Response</button>
        )}
        {getSearchParam("rfq_view_id") && (
          <button onClick={handlePublishedHistoryHandler} disabled={rfqHeader?.rfq_status === 0}>
            Published History
          </button>
        )}
      </div>
    );
  };


  const handleInitiateEvaluationClick = () => {
    if(new Date()<new Date(rfqHeader?.expiry_datetime)){
      Helper.alert("You can not initiate technical evaluation, because Request for Quotation is not expire yet.","error")
      return
    }          
      let api = Api;
      api.setUserToken();
      setIsHeaderLoading(true)
      api
        .axios()
        .post(
          Settings.apiPurchaseRequisitionUrl +
          "/initiate_evaluation/" +
          rfqHeader?.RFQ_id,
          {
            description: null,
            evaluation_type: 0,
          }
        )
        .then((res) => {
          setViewComponent(ViewHeaderListTechnical[0]);
          setIsHeaderLoading(false) 
          getRFqHeaderData()
          Helper.alert(res?.data?.message, "success");
        })
        .catch((error) => {
          Helper.alert(error?.response?.data?.message, "error");
          setIsHeaderLoading(false) 
        });
  };

  const handleInitiateAwardingClick = () => {
    if(new Date()<new Date(rfqHeader?.expiry_datetime)){
      Helper.alert("You can not initiate awarding, because Request for Quotation is not expire yet.","error")
      return
    }
      let api = Api;
      api.setUserToken();
      setIsHeaderLoading(true)
      api
        .axios()
        .post(
          Settings.apiPurchaseRequisitionUrl +
          "/initiate_evaluation/" +
          rfqHeader?.RFQ_id,
          {
            description: null,
            evaluation_type: 2,
          }
        )
        .then((res) => {
          setIsHeaderLoading(false)
          getRFqHeaderData()
          Helper.alert(res?.data?.message, "success");
        })
        .catch((error) => {
          Helper.alert(error?.response?.data?.message, "error");
          setIsHeaderLoading(false)
        });
    
  };

  const handleInitiateBAFOClick = () => {
    const expiryCondition = new Date()<new Date(rfqHeader?.expiry_datetime)
    let status = tags[0]?.title?.toLowerCase()
    const statusCondition = rfqHeader?.rfq_status === 0 || rfqHeader?.rfq_status===2 || rfqHeader?.rfq_status===3 || rfqHeader?.rfq_status=== 7 
    if(statusCondition || expiryCondition){
      Helper.alert(`You can not initiate BAFO process, because Request for Quotation is ${expiryCondition? 'not expire yet':`in ${status} state`}.`,"error")
      return
    } else{
      setBafoPopup(true)
      setBafoBidType('bafo')
    }   
  }

  const handleReturnBidClick = () => {
    const expiryCondition = new Date()<new Date(rfqHeader?.expiry_datetime)
    let status = tags[0]?.title?.toLowerCase()
    const statusCondition = rfqHeader?.rfq_status !== 1
    if(statusCondition || expiryCondition){
      Helper.alert(`You can return bid, because Request for Quotation is ${expiryCondition? 'not expire yet':`in ${status} state`}.`,"error")
      return
    } else{
      setBafoPopup(true)
      setBafoBidType('return bid')
    }   
  }


  const handleRecallEvaluationClick = (evaluationType,workflowActionApiCall=false) => {
    if(new Date()<new Date(rfqHeader?.expiry_datetime)){
      Helper.alert(`You can not Recall ${evaluationType}, because Request for Quotation is not expire yet.`,"error")
      return
    }
    let type
    if (evaluationType === 'technical evaluation') {
      type = 0;
    } else if (evaluationType === 'commercial evaluation') {
      type = 1;
    } else if (evaluationType === 'awarding') {
      type = 2;
    }
      let api = Api;
      api.setUserToken();
      setIsHeaderLoading(true);
      if(workflowActionApiCall){
        setApiCallStateWF(true);
      }  
      api
        .axios()
        .get(
          Settings.apiPurchaseRequisitionUrl +
          "/recall_evaluation/" +
          rfqHeader?.RFQ_id + `/${type}`   
        )
        .then((res) => {
          setViewComponent(ViewHeaderListTechnical[0]);
          setIsHeaderLoading(false)
          getRFqHeaderData() 
          if (workflowActionApiCall) {
            executeFunctionTaskActionApi()
          }
          Helper.alert(res?.data?.message, "success");
        })
        .catch((error) => {
          Helper.alert(error?.response?.data?.message, "error");
          setIsHeaderLoading(false)
          if(workflowActionApiCall){
            setApiCallStateWF(false);
          } 
        });
     
  };

  const handleInitiateCommercialEvaluationClick = () => {
    if(new Date()<new Date(rfqHeader?.rfq_type===2?rfqHeader?.auction_end_date_time:rfqHeader?.expiry_datetime)){
      Helper.alert("You can not initiate commercial evaluation, because Request for Quotation is not expire yet.","error")
      return
    }
    
    setIsHeaderLoading(true);
      let api = Api;
      api.setUserToken();
      api
        .axios()
        .post(
          Settings.apiPurchaseRequisitionUrl +
          "/initiate_evaluation/" +
          rfqHeader?.RFQ_id,
          {
            description: null,
            evaluation_type: 1,
          }
        )
        .then((res) => {
          Helper.alert(res.data.message,"success")
          setIsHeaderLoading(false);
          getRFqHeaderData()
          getRFQScores()
        })
        .catch((error) => {
          setIsHeaderLoading(false);
          Helper.alert(error?.response?.data?.message, "error");
        });
  };
 
  const canShowHeader = () => {
      if(props?.auth?.user?.is_buyer || teamData?.RFP_view_access || (teamData?.RFP_view_access && (teamData?.access_level===1) ) ){
        return true
      }
  };

  const canPerformEvaluation = (evaluation_type) => {
      if(evaluation_type==='technical' && rfqHeader?.rfq_type===2){
        return false
      }
      if(props?.auth?.user?.is_buyer){
        return true
      }
      else if(teamData?.perform_RFQ_technical_evaluation && evaluation_type==="technical"){
        return true
      }
      else if(teamData?.perform_RFQ_financial_evaluation && evaluation_type==="financial"){
        return true
      }
      else if(teamData?.allowed_to_award && evaluation_type==="awarding"){
        return true
      } else{
        return false
      }
  };

  const onCreateFirstDistribution = (newDisSouceId) => {
    let api = Api;

    api.setUserToken();
    api
      .axios()
      .put(
        Settings.apiPurchaseRequisitionUrl + "/rfq_header/" + rfqHeader?.RFQ_id,
        {
          distributionsource_id: newDisSouceId,
        }
      )
      .then((res) => { });
    setRfqHeader({ ...rfqHeader, distributionsource_id: newDisSouceId });
  };

  const displayViewAttachedLogo = (params) => {
    return <div className="d-flex align-items-center justify-content-center" style={{marginLeft:"-17px"}}><div className="position-relative"><img src="/images/icons/attach.svg" style={{height:"24px"}} />{params?.data?.attachment_exists && <div className="orange-attachment-dot" style={{top:"8.5px"}}></div>}</div></div>
  };

  const get_rfq_attachments = (id,source_id) => {
    let api = Api;
    api.setUserToken();
     api
      .axios()
      .get(Settings.loginUrl + `/get_attachments/${source_id}/${id}?attachment_for=1`)
      .then(function (res) {
        if (res.data.status === "success") {
          id !== null
            ? setRFQAttachments(res.data?.data)
            : setRFQAttachments(null);
        }
        lineObj?.api?.refreshServerSide({purge:false})
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res) ;
      });
  };

  const attachmentCellClick = (event) => {
    if (event.colDef.field === "attached") {
      setShowModal(true);
      let scrollY = window.scrollY || document.documentElement.scrollTop;
      let mouseY = event.event.clientY;
      let absoluteMouseY = mouseY + scrollY;
      let mouseX = event.event.clientX;
      let absoluteMouseX = window.innerWidth - mouseX - 100;
      setPopUpPosition({ top: absoluteMouseY, right: absoluteMouseX });
      const attachedId = event.data.attachmentsource_id 
      const fetchId =  event.data.line_id
      setLineId(fetchId)
      setAttachmentSourceId(attachedId)
      setRFQAttachments([]);
      const source_id = rfqHeader?.rfq_type===2?92:61
      get_rfq_attachments(fetchId,source_id);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleExtenstionRequestsPopup = () => {
    setExtensionRequestsPopup(false)
  }

  const mainTitle = () => {
    const rfqViewId = getSearchParam("rfq_view_id")
    const typeParam = getSearchParam("type");
    if (rfqViewId && typeParam === "RFI") {
      return Helper.getLabel(
        props.language,
        "requestion_for_informations",
        "Requestion for Informations"
      );
    } else if(rfqHeader?.rfq_type===2 || location?.state?.rfqType===2){
      return Helper.getLabel(
        props.language,
        "request_for_quotation - auction",
        "Request for Quotation - Auction"
      );
    }
    else if (rfqViewId) {
      return Helper.getLabel(
        props.language,
        "request_for_quotation",
        "Request for Quotation"
      );
    }
  };

  const updateEvaluatorResponse = (submitted,requested_retender,score_rfq_evaluator_id=null,workflowActionApiCall=false) => {
    let payload = !score_rfq_evaluator_id ? {
      submitted: submitted,
      requested_retender: requested_retender,
      reason_for_selection: reasonForSelection
    } : {
      submitted: submitted,
      requested_retender: requested_retender
    }
    
    if(score_rfq_evaluator_id || workflowActionApiCall){
      setApiCallStateWF(true);
    }  
    let api = Api;
    api.setUserToken();
    api
      .axios()
      .put(
        Settings.apiPurchaseRequisitionUrl +
        `/update_evaluator/${!score_rfq_evaluator_id?scoreRfqEvaluatorId:score_rfq_evaluator_id}`,payload
      )
      .then((res) => {
        Helper.alert(res.data.message, "success");
        if (score_rfq_evaluator_id) {
          executeFunctionTaskActionApi();
        } else {
          if (workflowActionApiCall) {
            executeFunctionTaskActionApi();
          }
          getTechData();  
        }
      }).catch((err) => {
        if(score_rfq_evaluator_id || workflowActionApiCall){
          setApiCallStateWF(false);
        } 
      })
  }
  
  const TechnicalEvaluationFormValidationChecks = () => {
    let isValid = true;

    const inputFieldsSliderValues = checkInputFieldsSliderValues();
    const requiredTextareasfilled = checkTextAreasHavingValues();

    if (evaluatorData?.reason_for_selection === "" || !evaluatorData?.reason_for_selection) {
      Helper.alert('Please provide vendor reason for selection', 'error');
      isValid = false;
    }

    if (checkLineRecommendations(technicalScoreSummary)) {
      Helper.alert('Please recommend a few lines to the vendor of your choice before proceeding', 'error');
      isValid = false;
    }
    
    if (!requiredTextareasfilled) {
      Helper.alert('Please fill out the required comment fields', 'error');
      isValid = false;
    }

    if(!inputFieldsSliderValues) {
      Helper.alert('Please complete score fields. Each score currently has a value of 0', 'error');
      isValid = false;
    }
   
    return isValid;
  }; 
  
  const CommercialEvaluationFormValidationChecks = () => {
    let isValid = true;
    
    if(checkLinesEitherAwardedPreferred(scoreSummaryCommercial, props?.user?.employee_id)){
      Helper.alert('Please award or prefer a few lines to the vendor of your choice before proceeding', 'error');
      isValid = false;
    }
    
    if (reasonForSelection === "" || !reasonForSelection) {
      Helper.alert('Please provide reason for selection', 'error');
      isValid = false;
    }

    return isValid;
  };

  const onRequestedRetender = () => {
    if (!scoreRfqEvaluatorId) return;
    updateEvaluatorResponse(false,true)
  };

  const handleSubmitClickRfq = () => {
    if (!scoreRfqEvaluatorId) return;
    
    if(CommercialEvaluationFormValidationChecks()) {  
      updateEvaluatorResponse(true,false,null,true)
    }
  };
 
  const FuntionPropsForRetenderBtn = (props) => { executeRequestRetenderBtn = props };
  const FuntionPropsForTaskActionApi = (props) => { executeFunctionTaskActionApi = props };
  
  const handleSubmitClickRfqTechnical = () => {
    if(evaluatorData?.requested_retender){
      Helper.alert("You can't submit now, because you requested for retender request for quotation.","error")
    } else {
      setIsSubmittedTechnical(true);
      if(TechnicalEvaluationFormValidationChecks()) {
        updateEvaluatorResponse(true, false, evaluatorData?.score_rfq_evaluator_id)
      }    
    }
  };

  const onRequestedRetenderTechnical = () => {
    if(evaluatorData?.requested_retender){
      return
    }
    setIsSubmittedTechnical(false); 
    if(typeof executeRequestRetenderBtn !== 'function') {
      Helper.alert(`Please review any user's evaluation before submitting a retender request`,'error');
      return;
    }
    Helper.confirmMe(() => {
      executeRequestRetenderBtn()
    },"Your Request for Quotation will be re-tender.", null, `Do you want to request a retender for this quotation?`,520)
  };
  const getTechData  = () => {
    let scoreRFQId = rfqScoresState?.scorerfq_id
    if(!getSearchParam('rfq_view_id') || !props?.auth?.user?.employee_id || !scoreRFQId) return;
    if(selectedSupNav !== "Commercial Evaluation") return; 
    let url = Settings.apiPurchaseRequisitionUrl + `/evaluator/${getSearchParam('rfq_view_id')}?employee_id=${props.auth?.user?.employee_id}&scoreRFQ_id=${scoreRFQId}`
    if(selectedSupNav === "Commercial Evaluation"){
      url += `&evaluation_type=1`
    }
    let api = Api;
    api.setUserToken();
    api
      .axios()
      .get(url)
      .then((res) => { 
        
        setEvaluatorData(res.data?.data)
        if(res?.data?.data?.submitted){setReasonForSelection(res?.data?.data?.reason_for_selection)}
        setScoreRfqEvaluatorId(res?.data?.data?.score_rfq_evaluator_id);
      });
  };

  const getRFQScores = () => {
    if (!getSearchParam('rfq_view_id') || !props?.auth?.user?.employee_id) return;
    if(selectedSupNav !== "Commercial Evaluation") return;

    let api = Api;
    api.setUserToken();
    api.axios().get(Settings.apiPurchaseRequisitionUrl + `/get_rfq_scores/${getSearchParam('rfq_view_id')}?evaluation_type=1`, {})
    .then(function (res) {
        if (res.data.status === "success") {
            setRFQScoresState(res.data.data[0]);
        }
    })
    .catch((res) => {
        setRFQScoresState({});
    });
  };

  const AddressCreate = (data) => {
    if (rfqHeader?.addresssource_id !== null) {
      return
    }
    setRfqHeader({
      ...rfqHeader,
      addresssource_id:data.address_source_id,
    })
  }

  const ContactCreate = (data) => {
    if (rfqHeader?.contactsource_id !== null) {
      return
    }
    setRfqHeader({
      ...rfqHeader,
      contactsource_id: data.contact_source_id,
    })
  }

  const handleEvaluationOperationModalClose = () => {
    setEvaluationOperationModal(false)
    $('html').removeClass('popup_open');
  }

  const handleUnsealedOperationModalClose = () => {
    setUnsealedOperationModal(false)
    $('html').removeClass('popup_open');
  }

  const handleTemplatesModalClose = () => {
    setTemplatePopup(false)
    $('html').removeClass('popup_open');
  }

  const handleBafoModalClose = () => {
    setBafoPopup(false)
    $('html').removeClass('popup_open');
  }

  const handleBiddingDetailsModalClose = () => {
    setBiddingDetailsPopup(false)
    $('html').removeClass('popup_open');
  }
  
  useEffect(()=>{
    getTechData()
  },[selectedSupNav,rfqScoresState])

  useEffect(()=>{
    getRFQScores()
  },[selectedSupNav])        
 
  const isUserHasAccess = () => {
    if(teamData?.access_level !== 2){
      return true
    }else{
     return disableCondition
    }
  }
 
  const isFormDisable = (type) => {
    let isDisable
    if(rfqHeader?.onhold || rfqHeader?.rfq_status===2){
      isDisable=true
    }else{
    if(userRoleCondition){
     isDisable = isUserHasAccess()
    }else if (type==='controls' || type==='team'){
      if(userRoleCondition){
        isDisable = isUserHasAccess()
      }else{
      return rfqHeader?.rfq_status === 3
      }
    }
    else if(disableCondition){
      return disableCondition
    }
    }
    return isDisable
  }
  const isDisabled = isFormDisable() ;

  const isLinesDisabled = () => {
    let isLinesDisabled
    if(rfqHeader?.onhold || rfqHeader?.rfq_status===2){
      isLinesDisabled=true
    }
    else{
      if(userRoleCondition){
        isLinesDisabled = teamData?.access_level !==2 ? true : rfqHeader?.rfq_status !==0 && rfqHeader?.rfq_status !==9
      }else{
        isLinesDisabled = !(rfqHeader?.rfq_status === 0 || rfqHeader?.rfq_status ===9)
      }
    }
    return isLinesDisabled
  }

  const submitWorkflow = () => {};

  const executeLogicsBeforeTakeAction = (value,label) => { 
    if(label === 'View current assignment' || label === 'Recall') {
      setApiCallStateWF(false);
      return;
    };
  
    if(rfqHeader?.rfq_status === 5) {  
      if(selectedSupNav !== "Technical Evaluation" && !workflowTaskActions?.actions?.some(action => action?.value === '1')) {
        Helper.alert('Please check Technical Evaluation before taking any workflow action', 'error');
        return true;
      };
      if(selectedSupNav === "Technical Evaluation" && props?.user?.is_buyer && (isEmptyObject(componentTypeRendered) || componentTypeRendered?.label === "Consolidated View" || componentTypeRendered?.value !== props?.user?.employee_id) && !workflowTaskActions?.actions?.some(action => action?.value === '1')) {
        Helper.alert('Please open your user in Technical Evaluation before taking any workflow action', 'error');
        return true;
      };
    }
 
    if(rfqHeader?.rfq_status === 6) {
      if(selectedSupNav !== "Commercial Evaluation" && !workflowTaskActions?.actions?.some(action => action?.value === '1')) {
        Helper.alert('Please check Commercial Evaluation before taking any workflow action', 'error');
        return true;
      };
    }
  };
  
  const workFlowFunction = (type) => {
    if(rfqHeader?.rfq_status===5 && parseInt(type?.value)===parseInt(11)){
      handleSubmitClickRfqTechnical()
      return;
    }
    if(rfqHeader?.rfq_status===6 && parseInt(type?.value)===parseInt(11)){
      handleSubmitClickRfq()
      return;
    }
    if(rfqHeader?.rfq_status === 5 && parseInt(type?.value)===parseInt(6)){
      handleRecallEvaluationClick('technical evaluation', true)
      return;
    }
    if(rfqHeader?.rfq_status === 6 && parseInt(type?.value)===parseInt(6)){
      handleRecallEvaluationClick('commercial evaluation', true)
      return;
    }  
    setApiCallStateWF(true);
    executeFunctionTaskActionApi();
  }

  let commonUserDocumentAccess = userComingFromDashboard && location?.state?.prevRoutePath === '/dashboard';
  let isRFQVisible = props?.security?.canView(Gui_id_list.procure.request_for_quotation.request_for_quotation_edit_screen) || commonUserDocumentAccess;
  if (!isRFQVisible) { 
    return <AlertNew message="You do not have the necessary permissions to access this screen. Please contact your administrator for assistance." type="danger" />;
  }
 
  const noAccessCondition = userComingFromDashboard && !teamData ? false : !teamData && !props?.auth?.user?.is_buyer || (!props?.auth?.user?.is_buyer && !isHeaderLoading && !teamData?.RFP_view_access && !teamData?.perform_RFQ_technical_evaluation && !teamData?.perform_RFQ_financial_evaluation && !teamData?.allowed_to_award && teamData?.access_level === 0)
  if((!isHeaderLoading && noAccessCondition) || (!checkIfArrayIsEmpty(collaborationTeams)&&(userRoleCondition && !isHeaderLoading && teamData?.access_level===0))){
    if(checkIfArrayIsEmpty(collaborationTeams)) return
    return <AlertNew message="No Access" type="danger" ref={myDivRef} />
  }
 

  const onLineGridReady = (gridObj) => {
     lineObj = gridObj
  }

  const getBackUrl = () => {
    if(parseInt(location?.state?.status) === 0){
      return "/request-for-quotation/rfqheaderlist?status=0"
    }
    else if(location?.state?.vendor_reply){
      return "/request-for-quotation/rfqheaderlist?vendor_reply=true"
    }else{
      return "/request-for-quotation/rfqheaderlist"
    }
  }

  return (
    <div className="scrollBarBlinkControl">
    {saveBtn ? <OverlayLoader isLogoCenter={true}/>:null}
    {isHeaderLoading ? <OverlayLoader isLogoCenter={true} /> : (
    <div
      className={`rfq-internal-screens container-fluid`}
    >
        <div ref={myDivRef} >  
          <NavigationHeder
            removeMargin={true} 
            title={mainTitle()}
            backUrl={ location?.state?.isPrevPath === true || location?.state?.prevRoutePath === '/dashboard' || rfqHeader?.rfq_type===2 ? -1 : "/request-for-quotation/rfqheaderlist" }
            moreOptions={moreOptions}
            hideMoreBtn={(!props?.auth?.user?.is_buyer) || TechnicalEvaluationPreview}
          >
            <LinkBtn 
              isActive= {false} 
              to={ location?.state?.isPrevPath === true || location?.state?.prevRoutePath === '/dashboard' || rfqHeader?.rfq_type===2 ? -1 : getBackUrl() }
              title={Helper.getLabel(props.language,'000001',"Close")} 
              className='black-btn-style' 
            />
            {!rfqHeader ? (
              <ButtonSkeletonComponent /> 
            ) : (
              <> 
                {((getSearchParam("rfq_view_id")) && rfqHeader?.rfq_status !== 0) &&
                  rfqHeader?.rfq_type !== 2 &&
                  (selectedSupNav !== "Awarding" &&
                  selectedSupNav !== "Technical Evaluation" &&
                  selectedSupNav !== "Commercial Evaluation") && !TechnicalEvaluationPreview && (
                    <Button
                      isActive={false}
                      className="brown-btn-style"
                      title={Helper.getLabel(props.language, "expiry_control", "Expiry Control")}
                      isDisable={
                       rfqHeader?.rfq_status === 1 && new Date(rfqHeader?.expiry_datetime) < new Date() ? true : isDisabled || !rfqHeader?.allow_manual_extend_when_bid_is_open || rfqHeader?.onhold || rfqHeader?.rfq_status===2 || rfqHeader?.rfq_status === 9
                      }
                      onClick={onExtension}
                    />
                  )}
                {rfqHeader?.rfq_status !== 0 &&
                  selectedSupNav !== "Awarding" && !TechnicalEvaluationPreview &&
                  selectedSupNav !== "Technical Evaluation" &&
                  selectedSupNav !== "Commercial Evaluation" &&
                  rfqHeader?.rfq_type !==2  &&
                  getSearchParam("rfq_view_id") && (
                    <Button
                      isActive={false}
                      className="black-btn-style"
                      title={Helper.getLabel(props.language, "re-tender", "Re-tender")}
                      isDisable={rfqHeader?.rfq_status===2?false: rfqHeader?.rfq_type===2&&new Date() > new Date(rfqHeader?.auction_start_date_time)?true :isDisabled}
                      onClick={onRetender}
                    />
                  )}
                {
                  viewComponent === "Awarding" ||
                  viewComponent === "Technical Evaluation" ||
                  viewComponent === "Commercial Evaluation" && !TechnicalEvaluationPreview && (
                    <Button
                      isActive={false}
                      className="orange-btn-style"
                      title={Helper.getLabel(props.language, "print", "Print")}
                      icon={printIcon}
                    />
                  )}

                {getSearchParam("rfq_view_id") &&
                  selectedSupNav === "Technical Evaluation" && (
                    <Button
                      isActive={false}
                      className="red-btn-style"
                       title={Helper.getLabel(
                        props.language,
                        "request_retender",
                        "Request Retender"
                      )} 
                      isDisable=
                      {
                        (
                          rfqHeader?.onhold || rfqHeader?.rfq_status === 2
                            ? true
                            : selectedSupNav === "Commercial Evaluation"
                              ? (
                                  rfqHeader?.rfq_status !== 5 &&
                                  rfqHeader?.rfq_status !== 6 &&
                                  rfqHeader?.rfq_status !== 7
                                )
                              : (
                                  isSubmittedTechnical ||
                                  (rfqHeader?.rfq_status !== 5 && rfqHeader?.rfq_status !== 6 && rfqHeader?.rfq_status !== 7) ||
                                  evaluatorData?.requested_retender ||
                                  evaluatorData?.submitted
                                )
                        ) || (
                          selectedSupNav === "Technical Evaluation" && !disableFormComponent
                        )
                      }
                      onClick={
                        viewComponent === "Commercial Evaluation"
                          ? onRequestedRetender
                          : onRequestedRetenderTechnical
                      }
                    /> 
                  )}
                {(getSearchParam("rfq_view_id") && !TechnicalEvaluationPreview &&
                  selectedSupNav !== "Technical Evaluation" &&
                  selectedSupNav !== "Commercial Evaluation" && selectedSupNav !== "Awarding") &&                            
                 (
                    <Button
                      isActive={false}
                      className="rfq_save_btn"
                      title={Helper.getLabel(props.language, "save", "Save")}
                      onClick={handleSaveClick}
                      isDisable={rfqHeader?.onhold || rfqHeader?.rfq_status===2 || saveBtn ?true:disableCondition && rfqHeader?.rfq_status !==5 && rfqHeader?.rfq_status !==6 && rfqHeader?.rfq_status !==7}
                    />
                  )}

                {getSearchParam("rfq_view_id") && !TechnicalEvaluationPreview &&
                  selectedSupNav !== "Technical Evaluation" &&
                  selectedSupNav !== "Commercial Evaluation" && selectedSupNav !== "Awarding" && (
                    <Button
                      isActive={false}
                      className="rfq_vendor_reply_publish_btn"
                      title={Helper.getLabel(props.language, "publish", "Publish")}
                      onClick={handlePublishClick}
                      isDisable={(rfqHeader?.rfq_status===1 && new Date(rfqHeader?.rfq_type===2?rfqHeader?.auction_end_date_time:rfqHeader?.expiry_datetime) < new Date())?true:isDisabled}
                    />
                  )}

                  {/* <TotalWidget
                    source_id={Settings.source_id?.request_for_quotation}
                    integrator={getSearchParam('rfq_view_id')}
                    isDisable={rfqHeader?.onhold || rfqHeader?.rfq_status===2?true:rfqHeader?.rfq_status !== 7 && rfqHeader?.rfq_status !== 3}
                  /> */}
              </>
            )}
          </NavigationHeder>  
          {rfqHeader?.onhold && show && (
          <Alert variant='dark' onClose={handleClose} dismissible>
            <p className='mb-0'>This RFQ is in hold state. you can't make any change in this RFQ.</p>
          </Alert>
          )}
          {workflowExist?.instance_exist ?
            <div style={{ marginTop: '24px', display: scrollDitection == 'down' ? "block" : "none" }}>
              <WorkFlowCommonHeader
                submitWorkflowAction={
                  !workflowExist?.instance_exist &&
                  workflowEligible &&
                  submitWorkflow
                }
                disableTakeActionBtns={evaluatorData?.requested_retender === true && selectedSupNav === "Technical Evaluation"}
                enableFields={disableFormComponent}
                setEnableFields={setDisableFormComponent}
                setUnderWorkflowState={setUnderWorkflow}
                workflowInstanceDetails={workflowExist?.instance_details}
                executeLogicsBeforeTakeAction={executeLogicsBeforeTakeAction}
                setWorkflowTaskActions={setWorkflowTaskActions}
                instance_task_id={getSearchParam('instance_id')}
                module_id={!getSearchParam('instance_id') ? getSourceIdForWorkflowTrigger(rfqHeader?.rfq_status,rfqHeader?.rfq_type)?.value : null}
                source_id={getSourceIdForWorkflowTrigger(rfqHeader?.rfq_status,rfqHeader?.rfq_type)?.value}
                source_item_id={getSearchParam('rfq_view_id')}
                SubmitWorkflowObjDetails={workflowExist?.workflow_name}
                module_item_id={!getSearchParam('instance_id') ? getSearchParam('rfq_view_id') : null}
                workflowInstanceExist={workflowExist?.instance_exist}
                workflowEligible={workflowEligible}
                workflowName={getSourceIdForWorkflowTrigger(rfqHeader?.rfq_status,rfqHeader?.rfq_type)?.label}
                reloadFunction={workFlowReloadFunction}
                workFlowFunction={(type)=>{workFlowFunction(type)}}
                executeApiOfTaskAction={FuntionPropsForTaskActionApi}
                apiCallState={apiCallStateWF}
                updateApiCallState={setApiCallStateWF}
              />
            </div>
          : ''}
          <FixedNavigationHeder
            addComponentTop={addComponentTop}
            sectionName={sectionName}
            navs={canShowHeader() && !TechnicalEvaluationPreview?navs:[{
              title: Helper.getLabel(props.language, "general", "General"),
              data_section: "one", active_status: 'active_tab',
            }]}
            scrollDitection={scrollDitection}
            dynamic={true}
            selectedSupNav={selectedSupNav}
            setSelectedSupNav={setSelectedSupNav}
            superNav={switchViewDynamicSelector()}
          /> 
        </div>
            <TabContentWraper
              height={height }
              setScrollDitection={setScrollDitection}
              leftSideContent={
                <>
                  {rfqHeader &&
                    selectedSupNav == 'Header View' && (
                      <>
                        <div className="lists_contant one" id={`${sectionName}General`} style={{ position: "relative" }}>
                          <Collapse
                            title={Helper.getLabel(
                              props.language,
                              "general_information",
                              "General Information"
                            )}
                            open={true}
                            className="mb-5"
                          >
                              <GeneralInformationForm
                                rfqHeader={rfqHeader}
                                setRfqHeader={setRfqHeader}
                                updateRFQ={updateRFQ}
                                setUpdateRFQ={setUpdateRFQ}
                                employeesList={employeesList}
                                currencyList={currencyList}
                                securityLevelList={securityLevelList}
                                quotationTypeList={quotationTypeList}
                                auctionTypeList={auctionTypeList}
                                paymentTermList={paymentTermList}
                                modeOfDeliveryList={modeOfDeliveryList}
                                deliveryTermList={deliveryTermList}
                                isLoading={isHeaderLoading}
                                rfqStatus={getRFQStatusNameById()}
                                targetDocumentsList={targetDocuments}
                                buyersList={buyerList}
                                isDisabled={isDisabled}
                                user={props?.auth?.user}
                                teamData={teamData}
                                rfqStages={rfqStages}
                                numberSequenceData={numberSequence}
                                taxGroupList={taxGroup}
                                taxGroupItemList={taxGroupItem}
                                automaticTimeTypes={timeTypes}
                              />
                          </Collapse>
                        </div>
                       {canShowHeader() && !TechnicalEvaluationPreview && <> 
                       <div className="lists_contant two" id={`${sectionName}Vendors`} style={{ position: "relative" }}>
                            <Collapse
                              title={Helper.getLabel(props.language, "vendors", "Vendors")}
                              className="mb-5"
                              open={true}
                            >
                              {rfqHeader && (
                                <VendorsGrid
                                  language={props.language}
                                  updateVendorGridData={updateVendorGridData}
                                  newRows={newRows}
                                  updateVendorRow={updateVendorRow}
                                  rfqHeader={rfqHeader}
                                  setRfqHeader={setRfqHeader}
                                  vendorGridData={vendorGridData}
                                  setVendorGridData={setVendorGridData}
                                  isDisabled={isDisabled}
                                />
                              )}
                            </Collapse>
                        </div>
                        <div className="lists_contant three" id={`${sectionName}Collaboration Team`} style={{ position: "relative" }}>
                            <Collapse
                              title={Helper.getLabel(
                                props.language,
                                "collaboration_team",
                                "Collaboration Team"
                              )}
                              className="mb-5"
                              open={true}
                            >
                              <CollaborationTeamGrid
                                rfqHeader={rfqHeader}
                                employeesList={employeesList}
                                collaborationTeams={collaborationTeams}
                                setCollaborationTeams={setCollaborationTeams}
                                oldCollaborationTeams={oldCollaborationTeams}
                                setOldCollaborationTeams={setOldCollaborationTeams}
                                getAsyncData={getCollabTeamAsyncData}
                                integratorId={getSearchParam('rfq_view_id')}
                                isCollaborationTeamsDisable={isFormDisable('team')}
                                prevRoute={location?.state?.prevRoutePath}
                              />
                            </Collapse>
                        </div>
                        <div className="lists_contant four" id={`${sectionName}Segment`} style={{ position: "relative" }}>
                            <Collapse
                              className="mb-4"
                              open={true}
                              title={Helper.getLabel(props.language, "segment", "Segment")}
                            >
                              {rfqHeader && (
                                <SegmentsDistribution
                                  isEditable={!isDisabled}
                                  id="purchase_order_header_segments"
                                  disableFinancial={true}
                                  onCreateFirstDistribution={onCreateFirstDistribution}
                                  distribution_source_id={rfqHeader?.distributionsource_id}
                                  integrator={getIntegratorId()}
                                  source_id={rfqHeader?.source_id}
                                  totalAmount={100}
                                  accountingDate={new Date()}
                                />
                              )}
                            </Collapse>
                        </div>
                        {rfqHeader?.rfq_type===2 ? <></> : <div className="lists_contant five" id={`${sectionName}Scoring Criteria`} style={{ position: "relative" }}>    
                          <div className="mt-5">          
                            <ScoringCriteria
                              language={props.language}
                              RFQ_id={getSearchParam("rfq_view_id")}
                              rfqHeader={rfqHeader} 
                            />
                          </div>
                        </div>}
                        <div className="lists_contant six" id={`${sectionName}Controls`} style={{ position: "relative" }}>
                            <Collapse
                              title={Helper.getLabel(props.language, "controls", "Controls")}
                              className="mb-5 mt-5"
                              open={true}
                            >
                              <Controls rfqHeader={rfqHeader} setRfqHeader={setRfqHeader} updateRfqHeader={updateRFQ} setUpdateRfqHeader={setUpdateRFQ} isDisabled={isFormDisable('controls')} />
                            </Collapse>
                        </div>
                        <div className="lists_contant seven" id={`${sectionName}Compliances`} style={{ position: "relative" }}>
                          {getSearchParam("rfq_view_id") && !isEmptyObject(rfqHeader) ? 
                            ( 
                              <ComplianceTemplates rfq_id={rfqHeader?.RFQ_id} TransactionalLevel={true} disableComponent={(rfqHeader?.rfq_status === 2 || rfqHeader?.rfq_status === 3 || rfqHeader?.rfq_status === 1 || rfqHeader?.rfq_status === 5 || rfqHeader?.rfq_status === 6 || rfqHeader?.rfq_status === 7)} />
                            ) : (
                              <div></div>
                            )
                          }
                        </div>
                        <div className="lists_contant eight" id={`${sectionName}Terms & Conditions`} style={{ position: "relative" }}>
                          <ContractTerms transactional_document_type={[Settings.document_types.request_for_quotation]} disableComponent={(rfqHeader?.rfq_status === 3)} sourceintegration_id={rfqHeader?.RFQ_id} rfqType={rfqHeader?.rfq_type} />
                        </div>
                        <div className='lists_contant nine' id={`${sectionName}Address`} style={{ position: 'relative' }}>
                          <div className='mt-5'>
                            <AddressList
                              disableRecords={isDisabled}
                              onAddressCreate={AddressCreate}
                              addressSourc_id={rfqHeader?.addresssource_id}
                              source_id={rfqHeader?.rfq_type===2?Settings.source_id.auction:Settings.source_id.request_for_quotation}
                              address_source_integrator={getSearchParam('rfq_view_id')}
                            />
                          </div>
                        </div>

                      <div className='lists_contant ten' id={`${sectionName}Contact`} style={{ position: 'relative' }}>
                        <Collapse
                          title={Helper.getLabel(props.language, 'contact', 'Contact' )}
                          className={isDisabled?'rfq-line-view mb-5 mt-5':'mb-5 mt-5'}
                          open={true}
                        >
                        <ContactAddress
                          canEdit={!isDisabled}
                          contactsource_integrator={getSearchParam('rfq_view_id')}
                          address_id={null}
                          contactsource_id={rfqHeader?.contactsource_id}
                          source_id={rfqHeader?.rfq_type===2?Settings.source_id.auction:Settings?.source_id?.request_for_quotation}
                          onContactCreate={ContactCreate}
                        />
                        </Collapse>
                      </div> </>}
                    </>
                  )}

                {
                  selectedSupNav === "Line View" && (
                    <>
                      {isHeaderLoading ? (
                        <OverlayLoader isLogoCenter={true} />
                      ) : ( 
                      <div className="rfq-line-view mb-4">
                      <LineViewComponent
                        defaultValues={{ requester_id: rfqHeader?.requester_id }}
                        source_id={rfqHeader?.source_id}
                        line_source={rfqHeader?.source_id}
                        isEditable={!isLinesDisabled()}
                        lineGridTitle={Helper.getLabel(
                          props.language,
                          "request_for_quotation_lines",
                          "Request For Quotation Lines"
                        )}
                        header_id={rfqHeader?.RFQ_id}
                        purchase_status={null}
                        document_status={rfqHeader?.rfq_status}
                        delivery_date={null}
                        onbehalf_request={false}
                        vendor_id={null}
                        vendorsite_id={null}
                        requester_id={rfqHeader?.requester_id}
                        orderer_id={rfqHeader?.order_id}
                        setOrderStatusProps={null}
                        warehouse_id={rfqHeader?.warehouse_id}
                        taxgroup_item={null}
                        taxgroup_id={null}
                        entity_id={rfqHeader?.entity_id}
                        site_id={rfqHeader?.site_id}
                        onCellClicked={attachmentCellClick}
                        hideBillingRule={true}
                        prDisableCondition={!teamData?.PR_view_access}
                        formType="rfq"
                        unitPriceDefault={true}
                        disableFinancialInModule={true} 
                        onLineGridReady={onLineGridReady}
                        isPRLinkNeeded={true}
                        hideAdditionalInformationTab={true}
                        additionalLineColumns={
                          [
                            {
                              field: "attached",
                              minWidth: 120,
                              headerName: Helper.getLabel(
                                props.language,
                                "attachment",
                                "Attachment"
                              ),
                              cellClass: "rfq-attached-cell",
                              sortable:false,
                              cellRenderer: displayViewAttachedLogo,
                              filter:false
                            },
                          ]
                        }
                      />
                      </div>
                    ) } 
                    </>
                  )}

                {showModal && (
                  <RFQAttachmentPopup
                    closeModal={closeModal}
                    data={rfqAttachments}
                    language={props.language}
                    type={"line view"}
                    source_id={rfqHeader?.rfq_type===2?92:61}
                    attachmentsource_integrator={lineId}
                    setRfqAttachment={setRFQAttachments}
                    params={lineObj}
                    onAttachmentUpload={() => { get_rfq_attachments(lineId,rfqHeader?.rfq_type===2?92:61) }}
                    style={{
                      top: popUpPosition.top,
                      right: popUpPosition.right,
                    }}
                    attachmentsource_id={attachmentSourceId}
                  />
                )}
                
                  { selectedSupNav === "Technical Evaluation" && rfqHeader?.rfq_type !== 2 && ( 
                    <> 
                      {(
                        <TechnicalEvaluationWithConsolidatedView
                          setEvaluatorData={setEvaluatorData} 
                          setTechnicalScoreSummary={setTechnicalScoreSummary}
                          componentTypeRendered={setComponentTypeRendered} 
                          rfq_id={rfqHeader?.RFQ_id}
                          showPreviewBtn={!TechnicalEvaluationPreview}
                          employee_id={props.auth?.user?.employee_id}
                          screenHeight={height}
                          hideSidebarState={hideSidebarState}
                          evaluation_type={0}
                          orderer_id={rfqHeader?.orderer_id}
                          rfqHeader={rfqHeader} 
                          executeAPIForRetenderBtn={FuntionPropsForRetenderBtn}
                          readOnlyForm={evaluatorData?.submitted || rfqHeader?.onhold || rfqHeader?.rfq_status===2 || rfqHeader?.rfq_status!==5 || underWorkflow || !disableFormComponent} 
                          setRFQScoresState={setRFQScores}
                          RFQScoresState={rfqScores}
                        />
                      )}
                    </>
                  )}
  
                  {
                    selectedSupNav === "Commercial Evaluation" && (
                      <>
                        {isEmptyObject(rfqScoresState) ? <div>Commercial Evaluation is not initiated yet !</div>:rfqHeader && vendorGridData && (
                          <div>
                          <CommercialEvaluation
                            rfqHeader={rfqHeader}
                            scoreSummaryCommercial={setScoreSummaryCommercial}
                            reasonForSelection={reasonForSelection}
                            setReasonForSelection={setReasonForSelection}
                            evaluationData={evaluatorData}
                            user={props?.auth?.user}
                            currencyList={currencyList}
                          />
                          <div style={{height:"140px"}}></div>
                          </div>
                        )}
                      </>
                    )}

                {
                  selectedSupNav === "Compliance Consolidated" && (
                    <>
                      {rfqHeader && (
                        <CompliancesConsolidatedView
                          rfq_id={rfqHeader?.RFQ_id} 
                        />
                      )}
                    </>
                  )
                }

                {
                  selectedSupNav === "Awarding" && (
                      <RFQAwardingScreen
                        language={props.language}
                        rfqHeader={rfqHeader}
                      />
                  )}

                {publishModal && (
                  <PublishRFQModal
                    language={props.language}
                    setPublishModal={setPublishModal}
                    vendorGridData={vendorGridData}
                    setVendorGridData={setVendorGridData}
                    updateVendorGridData={updateVendorGridData}
                    setUpdateVendorGridData={setUpdateVendorGridData}
                    rfqHeader={rfqHeader}
                    setRefreshAfterPublish={setRefreshAfterPublish}
                  />
                )}
                {/* this is a Published History Modal */}
                {publishedHistoryModal && (
                  <PublishedHistoryModal
                    language={props.language}
                    updatePageUI={updatePageUI}
                    setUpdatePageUI={setUpdatePageUI}
                    rfqHeader={rfqHeader}
                    vendors={vendorGridData}
                    handleCloseModal={() => setPublishedHistoryModal(false)}
                    rfq_id={getSearchParam('rfq_view_id')}
                  />
                )}
                {extensionModal && (
                  <ExtensionModal
                    language={props.language}
                    updatePageUI={updatePageUI}
                    setUpdatePageUI={setUpdatePageUI}
                    rfqHeader={rfqHeader}
                    vendors={vendorGridData}
                    handleCloseModal={() => setExtensionModal(false)}
                  />
                )}

                {retenderModal && (
                  <RetenderModal
                    language={props.language}
                    vendorGridData={vendorGridData}
                    updatePageUI={updatePageUI}
                    setUpdatePageUI={setUpdatePageUI}
                    handleCloseModal={() => setRetenderModal(false)}
                    setRefreshAfterPublish={setRefreshAfterPublish}
                  />
                )}
                {
                  evaluationOperationModal && 
                  <EvaluationOperations 
                    handleCloseModal={handleEvaluationOperationModalClose}
                    language={props.language}
                    rfq_id={getSearchParam('rfq_view_id')}
                    rfqType={rfqHeader?.rfq_type}
                    setRefreshAfterPublish={setRefreshAfterPublish}
                  />
                }
                {
                  unsealedOperationModal && 
                  <UnsealedBidModal
                    language={props.language}
                    handleCloseModal={handleUnsealedOperationModalClose}
                    vendors={vendorGridData}   
                    rfqId={getSearchParam('rfq_view_id')}
                  />
                }
                {
                  extensionRequestsPopup && <ExtensionRequests 
                  rfq_id={getSearchParam('rfq_view_id')} 
                  language={props.language} 
                  handleCloseModal={handleExtenstionRequestsPopup}
                  setRefreshAfterPublish={setRefreshAfterPublish}
                  usertype={props?.auth?.user?.usertype} />
                }
                {
                  templatesPopup && <TemplateModal language={props.language}  rfq_id={getSearchParam('rfq_view_id')}  handleTemplateModalClose={handleTemplatesModalClose} source_id={rfqHeader?.rfq_type===2?Settings.source_id.auction:Settings.source_id.request_for_quotation}  />
                }
                {
                  bafoPopup && <BAFOModal language={props.language} handleBafoModalClose={handleBafoModalClose}  rfq_id={getSearchParam('rfq_view_id')} vendors={vendorGridData}  commonApiCalls={commonApiCalls} type={bafoBidType} setRefreshAfterPublish={setIsHeaderLoading}/>
                }
                {
                   biddingDetailsPopup && <RFQAuctionInnerPopup rfq_id={getSearchParam('rfq_view_id')} language={props.language} handleCloseModal={handleBiddingDetailsModalClose} />
                }
              </>
            }
            rightSideContent={addComponentRight}
          />
      </div>
    )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    user: state.auth.user,
    language: state.language,
  };
};

const SecurityOptions = {
  gui_id: Gui_id_list.procure.request_for_quotation.request_for_quotation_edit_screen
};
 
export default connect(mapStateToProps)(MasterComponentWraper(ApplySecurityRoles(RFQDetailForm, SecurityOptions))); 