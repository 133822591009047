import React, { useEffect, useState } from 'react'; 
import Popup from '../../Popup/Popup'
import Dropdown from '../../Forms/Dropdown'
import Helper from '../../../inc/Helper'
import RFQCommonApiCalls from '../RFQCommonApiCalls'
import Button from '../../inc/Button'
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation'
import Settings from '../../../inc/Settings'
import OverlayLoader from '../../PurchaseRequisition/common/OverlayLoader'

const TemplateModal = ({ handleTemplateModalClose, language, rfq_id,source_id }) => {
  const [templates, setTemplates] = useState([])
  const [apiCallMade, setApiCallMade] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null)

  const rfqCommonCalls = new RFQCommonApiCalls()

  const downloadConvertedTemplate = () => { handleConvertTemplate(true) };
  const openConvertedTemplateNewTab = () => { handleConvertTemplate(false) };

  const handleConvertTemplate = async (downloadReport=false) => {
    if(!selectedTemplate || selectedTemplate==='') {
      Helper.alert('Please select a template', 'error');
      return;
    };

    let payload = {
      rec_id: rfq_id,
      source_id: source_id ? source_id : Settings?.source_id?.request_for_quotation,
      template_id: selectedTemplate,
    };

    try {
      setApiCallMade(true);
      const convertRes = await rfqCommonCalls.convertToTemplate(payload)
      if (convertRes?.data) {  
        if(!downloadReport) { 
          const blob = new Blob([convertRes.data], { type: 'text/html' });
          const url = URL.createObjectURL(blob);
          window.open(url);
        } else {
          
        } 
        setApiCallMade(false);
      } 
    } catch (err) {
      getPopupMessageBasedOnStatus(err)
      setApiCallMade(false);
    }
  }

  const getAllTemplates = async () => {
    try {
      const templatesData = await rfqCommonCalls.getTemplates(source_id)
      let templatesList = []
      templatesData.forEach((item) => {
        templatesList?.push({
          value: item.rec_id,
          label: item.template_name,
        })
      })
      setTemplates(templatesList)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getAllTemplates()
  }, [])

  const onTemplateChange = (event) => {
    setSelectedTemplate(event.target.value)
  }

  return (
    <Popup autoOpen={true} width='900px' onClose={handleTemplateModalClose}>
      {apiCallMade && <OverlayLoader isPopupInTab={true} /> }
      <h5 className='create_rfq_modal__title mb-4'>
        Preview Document
      </h5>
      <div>
        <Dropdown
          id='templates-rfq'
          label={Helper.getLabel(language, 'templates', 'Templates')}
          options={templates}
          name='template_id'
          required={true}
          value={selectedTemplate}
          onChange={onTemplateChange}
        />
      </div>
      <div className='w-100 d-flex justify-content-end gap-3'> 
        {/* <Button title='Download' isActive={true} isDisable={apiCallMade} onClick={downloadConvertedTemplate} /> */}
        <Button title='Preview' className='rfq_save_btn' isDisable={apiCallMade} onClick={openConvertedTemplateNewTab} />
      </div>
    </Popup>
  )
}

export default TemplateModal
