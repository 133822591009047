import React, { useState } from 'react';  
// component for vendor reason selection textarea, dealing with individual state for separate-re-renders from parent component, 
// executeRFQEvaluatorApi its api function for executing on blur the api call
const VendorReasonSelection = ({vendorReasonDefaultVal, disableField, executeRFQEvaluatorApi}) => { 
    const [vendorReason, setVendorReason] = useState(vendorReasonDefaultVal); 
  
    const vendorReasonSelectionChange = (evt) => {
        let newText = evt.target.value;
        setVendorReason(newText); 
    };

    const handleOnBlur = (e) => {
        let value = e.target.value; 
        if(executeRFQEvaluatorApi) executeRFQEvaluatorApi(false,true,value); 
    }
    return (
        <div className="vendor_reason">
            <h2 className="vendor_selection_text"> Reason for Vendor Selection<span style={{color: 'red'}}>*</span></h2>
            <textarea
                disabled={disableField}
                value={vendorReason}
                required={true}
                onBlur={handleOnBlur}
                // maxLength={100}
                onChange={vendorReasonSelectionChange}
                id='reason_for_vendor_selection'
                className="descriptionTextarea"
            />
        </div>
    )
};
export default (VendorReasonSelection);