
class PoHelper{
    constructor(po,type){
        this.po = po;
        this.type=type
    }
    setPo(po,type){
        this.po = po;
        this.type = type
    }
    hasPo(){
        return this.type && this.type==="purchase agreement" ?  this.po.agreement_id : this.po.purchase_order_id;
    }
    canPoActionButtonActive(poActionKey,poActionId) {
        if (!this.hasPo()) {
            return false;
        }
        const purchaseStatus = this.po.purchase_status;
        const documentStatus = this.po.document_status;

        if (purchaseStatus === undefined) {
            return false;
        }

        if(poActionId === purchaseStatus){
            return false;
        }
    
        const isReadOnly = false;
    
        const isValidAction = (validStatus, validActions) => {
            return purchaseStatus === validStatus && validActions.includes(poActionKey);
        };
        
        const isDraft = documentStatus === 0;
        const isConfirm = documentStatus === 1;
        const isUnderReview = documentStatus === 2;
        const isRejected = documentStatus === 3;
        
        let commonStatus = ['confirmed', 'delete', 'invoice', 'goods_receiving_note', 'return_order', 'amendment_request','view_release_order'] 
        let receivedInvoicedStatus = ['confirmed','invoice', 'goods_receiving_note', 'return_order', 'amendment_request','view_release_order']
        if(purchaseStatus===7){
            commonStatus.push('reopen')
        }
        if(purchaseStatus===4){
            commonStatus.push('withdraw_freeze')
        }
        if(purchaseStatus===5){
            commonStatus.push('reopen')
        }

        if (isDraft) {
            if (isValidAction(0, commonStatus ) ||
                isValidAction(2, commonStatus) || isValidAction(3,commonStatus) || 
                isValidAction(4, commonStatus) || isValidAction(5, commonStatus)||isValidAction(6,commonStatus) || isValidAction(10,commonStatus) || isValidAction(7,commonStatus) || isValidAction(8,receivedInvoicedStatus) || isValidAction(9,receivedInvoicedStatus)) {
                return !isReadOnly;
            }
        } else if (isConfirm || isUnderReview) {
            if (isValidAction(0, ['close', 'edit', 'freeze', 'hold', 'cancel_document', 'goods_receiving_note', 'return_order', 'amendment_request', 'invoice', 'renew','return_order','view_release_order']) ||
                isValidAction(2, ['reopen','invoice','goods_receiving_note','amendment_request','return_order','view_release_order']) || isValidAction(3, ['close', 'edit', 'freeze', 'hold', 'cancel_document', 'goods_receiving_note', 'return_order', 'amendment_request', 'invoice', 'renew','return_order','view_release_order']) ||
                isValidAction(4, ['withdraw_freeze', 'hold', 'invoice', 'goods_receiving_note', 'return_order','amendment_request','view_release_order']) ||
                isValidAction(6, ['close', 'freeze', 'reopen', 'cancel_document', 'goods_receiving_note', 'return_order', 'amendment_request', 'invoice', 'renew', 'edit', 'hold','view_release_order']) ||
                isValidAction(5, ['reopen','invoice','goods_receiving_note','amendment_request','return_order','view_release_order']) || isValidAction(7, ['reopen','invoice','goods_receiving_note','view_release_order']) || isValidAction(10, ['reopen','amendment_request','invoice','goods_receiving_note','return_order','view_release_order']) || isValidAction(8,['invoice', 'goods_receiving_note', 'return_order', 'amendment_request','view_release_order'])||isValidAction(9,['invoice', 'goods_receiving_note', 'return_order', 'amendment_request','view_release_order'])) {
                return !isReadOnly;
            }
        } else if(isRejected) {
            if (poActionKey === 'edit'){
                return !isReadOnly;
            }
        }
    
        return false;
    }
}
export default PoHelper;