import React, { useEffect, useState } from 'react'
import MasterComonent from '../Backend/MasterComonent'
import NavigationHeder from '../Navigations/NavigationHeder'
import Helper from '../../inc/Helper'
import Collapse from '../inc/Collapse'
import Input from '../Forms/Input'
import Dropdown from '../Forms/Dropdown'
import Form from 'react-bootstrap/Form'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Button from '../inc/Button'
import Api from '../../inc/Api'
import Settings from '../../inc/Settings'
import { getPopupMessageBasedOnStatus } from '../../inc/Validation'
import { useLocation } from 'react-router-dom'
import NewInputDatePicker from '../Forms/NewInputDatePicker'
import DatepickerFunctions from '../../inc/DatepickerHelper'
import AgGridMemo from '../grid/ag/AgGridMemo'

let forceRender = 1

const QuestionDetails = (props) => {
  const [questionTypes, setQuestionTypes] = useState([])
  const [actionRequired, setActionRequired] = useState([])
  const [questionSequence, setQuestionSequence] = useState([])
  const [questionList, setQuestionList] = useState([])
  const [questionData, setQuestionData] = useState({})

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('questiongroup_id')

  const getQuestionTypes = () => {
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiUrl + `/enum/question_types`)
      .then(function (res) {
        if (res.data.status === 'success') {
          setQuestionTypes(res.data?.data)
        }
      })
      .catch((error) => {
        getPopupMessageBasedOnStatus(error)
      })
  }

  const getActionRequired = () => {
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiUrl + `/enum/action_if_time_complete_passed`)
      .then(function (res) {
        if (res.data.status === 'success') {
          setActionRequired(res.data?.data)
        }
      })
      .catch((error) => {
        getPopupMessageBasedOnStatus(error)
      })
  }

  const getQuestionSequence = () => {
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiUrl + `/enum/question_sequence`)
      .then(function (res) {
        if (res.data.status === 'success') {
          setQuestionSequence(res.data?.data)
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res)
      })
  }

  const getAllQuestions = () => {
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiUrl + `/getquestionsbygroup/${id}`)
      .then(function (res) {
        if (res.data.status === 'success') {
          setQuestionList(res.data?.data)
          forceRender++
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res)
      })
  }

  const onAddClick = () => {
    const element = document.getElementById('question-description-field')
    setQuestionData({})
    if (element) {
      element.focus()
    }
  }

  const onSelectionChanged = (event) => {
    let selectedRows = event?.api?.getSelectedRows()
    if (selectedRows?.length > 0) {
      setQuestionData({
        ...selectedRows[0],
        time_to_complete: DatepickerFunctions?.combineDateAndTime(selectedRows[0]?.time_to_complete),
      })
    } else {
      setQuestionData({})
    }
  }

  const onQuestionDelete = () => {
    if(!questionData?.question_id){
      return
    }
    Helper.confirmMe(() => {
      let api = Api
      api.setUserToken()
      api
      .axios()
      .delete(Settings.apiUrl + `/question/${questionData?.question_id}`)
      .then(function (res) {
        if (res.data.status === 'success') {
          Helper.alert(res.data.message)
          getAllQuestions()
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res)
      })
    }, "This action will delete your document.", null, "Are you sure you want to continue?", 350); 
  }

  const onSaveClickHandler = () => {
    let payload = {
      ...questionData,
      time_to_complete: DatepickerFunctions.convertTimeFromDate(questionData?.time_to_complete),
      weight: parseFloat(questionData?.weight),
    }
    let addPayload = {
      ...questionData,
      time_to_complete: DatepickerFunctions.convertTimeFromDate(questionData?.time_to_complete),
      questiongroup_id: id,
    }
    if (questionData?.question_id) {
      let api = Api
      api.setUserToken()
      api
        .axios()
        .put(Settings.apiUrl + `/question/${questionData?.question_id}`, payload)
        .then(function (res) {
          if (res.data.status === 'success') {
            Helper.alert(res.data.message)
            getAllQuestions()
          } else {
            Helper.alert(res.data.message, 'error')
          }
        })
        .catch((error) => {
          getPopupMessageBasedOnStatus(error)
        })
    } else {
      let api = Api
      api.setUserToken()
      api
        .axios()
        .post(Settings.apiUrl + '/question', addPayload)
        .then(function (res) {
          if (res.data.status === 'success') {
            Helper.alert(res.data.message)
            setQuestionData({
              ...questionData,
              question_id:res?.data?.questiongroup_id
            })
            getAllQuestions()
          } else {
            Helper.alert(res.data.message, 'error')
          }
        })
        .catch((error) => {
          getPopupMessageBasedOnStatus(error)
        })
    }
  }

  const handleQuestionDataChange = (event, type) => {
    let value = type === 'checkbox' ? event?.target?.checked : event.target.value
    setQuestionData({
      ...questionData,
      [event?.target?.name]: value,
    })
  }

  const handleTimeChange = (date) => {
    setQuestionData({
      ...questionData,
      time_to_complete: date,
    })
  }

  useEffect(() => {
    getQuestionTypes()
    getActionRequired()
    getQuestionSequence()
    getAllQuestions()
  }, [])

  let questionTypesList = questionTypes?.map((item) => {
    return {
      value: item.id,
      label: item.name,
    }
  })

  let actionRequiredList = actionRequired?.map((item) => {
    return {
      value: item.id,
      label: item.name,
    }
  })

  let sequenceList = questionSequence?.map((item) => {
    return {
      value: item.id,
      label: item.name,
    }
  })

  const Columns_Headings = [
    {
      field: 'question',
      headerName: Helper.getLabel(props.language, 'questions', 'Questions'),
      editable: true,
    },
  ]
  return (
    <div>
      <MasterComonent>
        <NavigationHeder title={`${Helper.getLabel(props.language, 'questions', 'Questions')}`} hideMoreBtn={true}>
          <Button title={Helper.getLabel(props.language, 'delete', 'Delete')} onClick={onQuestionDelete}></Button>
          <Button title={Helper.getLabel(props.language, 'save', 'Save')} icon='/images/icons/save-white.svg' isActive={true} onClick={onSaveClickHandler} />
        </NavigationHeder>
        <div>
          <div className='rfq-versions-container'>
            <div className='rfq-versions-left-sidebar rfq-question-detail-grid'>
              <AgGridMemo
                forceRender={forceRender}
                hideDelete={true}
                header={Columns_Headings}
                data={questionList}
                addBtnHide={false}
                hideSave={true}
                onAddClick={onAddClick}
                hideFooter={true}
                onSelectionChanged={onSelectionChanged}
                singleRowSelection={true}
              />
            </div>
            <div className='w-100'>
              <div>
                <Collapse open={true} title={Helper.getLabel(props.language, 'questions', 'Questions')}>
                  <div className='question-details-form'>
                    <div className='row'>
                      <div className='col-sm-12'>
                        <Input
                          label={`${Helper.getLabel(props.language, 'question', 'Question')}`}
                          inputId='question-description-field'
                          name='question'
                          onChange={(event) => handleQuestionDataChange(event)}
                          value={questionData?.question}
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-sm-6'>
                        <Dropdown
                          label={`${Helper.getLabel(props.language, 'input_type', 'Input type')}`}
                          placeHolder={`${Helper.getLabel(props.language, 'select_input_type', 'Select Input Type')}`}
                          id='question-type-list'
                          name='question_type'
                          options={questionTypesList}
                          onChange={(event) => handleQuestionDataChange(event)}
                          value={questionData?.question_type}
                          reRenderRequired={true}
                        />
                      </div>
                      <div className='col-sm-6'>
                        <Dropdown
                          label={`${Helper.getLabel(props.language, 'sequence', 'Sequence')}`}
                          placeHolder={`${Helper.getLabel(props.language, 'select_sequence', 'Select Sequence')}`}
                          id='question-sequence-list'
                          name='sequence'
                          options={sequenceList}
                          onChange={(event) => handleQuestionDataChange(event)}
                          value={questionData?.sequence}
                          reRenderRequired={true}
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-sm-6'>
                        <Dropdown
                          label={`${Helper.getLabel(props.language, 'answer_group', 'Answer group')}`}
                          placeHolder={`${Helper.getLabel(props.language, 'select_answer_group', 'Select Answer group')}`}
                          id='answer-group-list'
                          name='answergroup_id'
                          options={[]}
                          onChange={(event) => handleQuestionDataChange(event)}
                          value={questionData?.answergroup_id}
                          reRenderRequired={true}
                        />
                      </div>
                      <div className='col-sm-6'>
                        <Input label={`${Helper.getLabel(props.language, 'weight', 'Weight')}`} name='weight' onChange={(event) => handleQuestionDataChange(event)} value={questionData?.weight} />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-sm-6'>
                        <Input
                          label={`${Helper.getLabel(props.language, 'minimum_required_percentage', 'Minimum required percentage')}`}
                          name='minimum_required_percentage'
                          onChange={(event) => handleQuestionDataChange(event)}
                          value={questionData?.minimum_required_percentage}
                        />
                      </div>
                      <div className='col-sm-6'>
                        <NewInputDatePicker
                          label={`${Helper.getLabel(props.language, 'total_time_to_complete_question', 'Total time to complete the question')}`}
                          name='time_to_complete'
                          value={questionData?.time_to_complete}
                          onChange={handleTimeChange}
                          timePicker={true}
                          timeOnly={true}
                          empty={true}
                        />
                      </div>
                    </div>
                    <div className='row align-items-center'>
                      <div className='col-sm-6'>
                        <Dropdown
                          label={`${Helper.getLabel(props.language, 'action_after_time_passed', 'Action after time passed')}`}
                          placeHolder={`${Helper.getLabel(props.language, 'select_action_after_time_passed', 'Select Action After Time Passed')}`}
                          id='action-required-list'
                          name='action_if_time_complete_passed'
                          options={actionRequiredList}
                          onChange={(event) => handleQuestionDataChange(event)}
                          value={questionData?.action_if_time_complete_passed}
                          reRenderRequired={true}
                        />
                      </div>
                      <div className='col-sm-6 mb-4'>
                        <div className='questionnaire-check-box'>
                          <Form.Check
                            label={
                              <div className='d-flex flex-column'>
                                <span>
                                  Mandatory{' '}
                                  <OverlayTrigger placement='right' delay={{ show: 250, hide: 400 }} overlay={<Tooltip>Sample</Tooltip>}>
                                    <img src='/images/icons/info-circle-logo.svg' alt='info-blue-logo' />
                                  </OverlayTrigger>
                                </span>
                              </div>
                            }
                            name='is_mandatory'
                            checked={questionData?.is_mandatory}
                            onChange={(event) => handleQuestionDataChange(event, 'checkbox')}
                            value={questionData?.is_mandatory}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-sm-12'>
                        <Input label={`${Helper.getLabel(props.language, 'instruction', 'Instruction')}`} name='instruction' onChange={(event) => handleQuestionDataChange(event)} value={questionData?.instruction} />
                      </div>
                    </div>
                  </div>
                </Collapse>
              </div>
            </div>
          </div>
        </div>
      </MasterComonent>
    </div>
  )
}

export default QuestionDetails
