import React from 'react'
import { connect } from 'react-redux'
import Helper from '../../../inc/Helper'
import Collapse from '../../inc/Collapse'
import Tabs from '../../Widgets/Tabs/Tabs'
import GeneralTab from './GeneralTab'
import SetupTab from './SetupTab'

const HeaderViewGeneral = (props) => {
    let generalTabs = [
        {title:Helper.getLabel(props.language,'basic','Basic'), isActive: true}, 
        {title:Helper.getLabel(props.language,'setup','Setup')}  
    ]
  return (
    <Collapse className='mb-5 general_info' open={true} title={Helper.getLabel(props.language,'general_information',"General Information")}>
      <Tabs disableSticky={true} navs={generalTabs} showAll={false} scrollOffset="300">
        <div>
          <GeneralTab document_status_label={props.document_status_label} purchase_status_label={props.purchase_status_label} isEditable={props.isEditable} taxGroups={props.taxGroup} taxCodes={props.taxCode} vatTypes={props.vatTypes} disableField={props.isDisable} ApiData={props.vendorsDropdown} setState={props.setState} state={props.state} type={props.type} isDocumentNumberDisable={props.isDocumentNumberDisable} />
        </div>

        <div>
          <SetupTab visibleEndDate={props.visibleEndDate} visibleStartDate={props.visibleStartDate} isEditable={props.isEditable} updateOrderStatusWidget = {props.updateOrderStatusWidget} sites={props.sites} entities={props.entities} requester={props.requesterAPI} ordered={props.orderedAPI} setState={props.setState} state={props.state} type={props.type}/>
        </div>
      </Tabs> 
    </Collapse> 
  )
}

 
const mapStateToProps = (state) => {
    return {
      appOptions:state.options,
      auth:state.auth,
      language:state.language
    }
  }
 


export default connect(mapStateToProps) (HeaderViewGeneral) 